import { createSlice } from "@reduxjs/toolkit";
// import { FlightListsDataState } from "../models/models";

const initialState = {
  airAwardBookingInfo: {},
  isLoading: false,
  hasData: false,
  error: ""
};
const airAwardBookingSlice = createSlice({
  name: "airAwardBooking",
  initialState,
  reducers: {
    airAwardBookingRequest: state => {
      state.isLoading = true;
    },
    airAwardBookingSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.airAwardBookingInfo = action.payload;
    },
    airAwardBookingFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    airAwardBookingReset: state => {
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.airAwardBookingInfo = {};
    }
  }
});
export const {
  airAwardBookingRequest,
  airAwardBookingSuccess,
  airAwardBookingFailure,
  airAwardBookingReset
} = airAwardBookingSlice.actions;
export default airAwardBookingSlice.reducer;