import { call, put, takeLatest } from "redux-saga/effects";
import { fetchMyProfileMilesRequest, getMyProfileMilesSuccess, getMyProfileMilesFailure } from "../../slice/fetchMilesSlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
const accessToken = sessionStorage.getItem("accesstoken");
const data = {
  mode: "no-cors",
  headers: {
    // memberId: sessionStorage.getItem("memberId"),
    // Authorization: sessionStorage.getItem("accesstoken"),
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: accessToken
  },
  loader: true
};
const fetchMyProfileMilesData = async () => {
  const FETCH_MAIN_MEMBER_PROFILE_MILES_API_URL = config.FETCH_MAIN_MEMBER_MILES;
  const response = await api.get(FETCH_MAIN_MEMBER_PROFILE_MILES_API_URL, data);
  return response;
};
function* getProfileMilesData() {
  try {
    const response = yield call(fetchMyProfileMilesData);
    yield put(getMyProfileMilesSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(getMyProfileMilesFailure(error.message));
  }
}
export function* watchMainProfileMilesData() {
  yield takeLatest(fetchMyProfileMilesRequest.type, getProfileMilesData);
}