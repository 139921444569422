import { call, put, takeEvery } from "redux-saga/effects";
import { flightFareRequest, flightFareSuccess, flightFareFail } from "../slice/flightFareSlice";
import api from "../../../config/api";
import { config } from "../../../config/global";
const API_URL = config === null || config === void 0 ? void 0 : config.FLIGHT_FARE_API;
const fetchFlightFare = async payload => {
  let headerData = {
    headers: {
      memberId: sessionStorage.getItem("memberId"),
      Authorization: localStorage.getItem("accesstoken"),
      "Content-Type": "application/json"
    }
  };
  const response = await api.post(API_URL, payload, headerData);
  return response;
};
function* fetchFlightFareSaga(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchFlightFare, payload);
    yield put(flightFareSuccess({
      ...response
    }));
  } catch (error) {
    yield put(flightFareFail({
      ...(error === null || error === void 0 ? void 0 : error.response)
    }));
  }
}
export function* watchFlightFare() {
  yield takeEvery(flightFareRequest.type, fetchFlightFareSaga);
}