import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { getUnsubscribeDataPending, getUnSubscribeSuccess, getUnSubscribeFailure } from "../slice/unsubscribeSlice";
const UNSUBSCRIBE_RESET_URL = "common/subscription/unsubscribe";
const fetchUnsubscribeResetData = async UnsubscribePayload => {
  let unsubscribePayloadData = {
    email: UnsubscribePayload.email
  };
  let headerData = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  const response = await api.post(UNSUBSCRIBE_RESET_URL, unsubscribePayloadData, headerData);
  return response;
};
function* getUnsubscribeData(action) {
  try {
    const response = yield call(fetchUnsubscribeResetData, action.payload);
    yield put(getUnSubscribeSuccess(response.data));
  } catch (error) {
    yield put(getUnSubscribeFailure(error));
  }
}
export function* watchUnsubscribeData() {
  yield takeLatest(getUnsubscribeDataPending.type, getUnsubscribeData);
}