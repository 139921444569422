import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  CountryDetails: [],
  isLoading: false,
  error: "",
  hasData: false
};
const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    countryDataRequest: state => {
      state.isLoading = true;
    },
    getCountryDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.CountryDetails = action.payload;
    },
    getCountryDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});
export const {
  countryDataRequest,
  getCountryDataFailure,
  getCountryDataSuccess
} = countrySlice.actions;
export default countrySlice.reducer;