import { call, put, takeEvery } from "redux-saga/effects";
import { config } from "../../../config/global";
import api from "../../../config/api";
import { deleteTripCardDataRequest, deleteTripCardDataSuccess, deleteTripCardDataFailure } from "../slice/deleteTripCardSlice";
const API_URL = config === null || config === void 0 ? void 0 : config.DELETE_TRIP_CARD;
const deleteTriCardData = async payload => {
  let headerData = {
    headers: {
      Authorization: localStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
      memberId: sessionStorage.getItem("memberId")
    },
    loader: true,
    data: {
      pnr: payload
    }
  };
  const response = await api.delete(API_URL, headerData);
  return response;
};
function* deleteTriCardDataSaga(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(deleteTriCardData, payload);
    yield put(deleteTripCardDataSuccess({
      ...response
    }));
  } catch (error) {
    yield put(deleteTripCardDataFailure({
      ...(error === null || error === void 0 ? void 0 : error.response)
    }));
  }
}
export function* watchDeleteTripCardData() {
  yield takeEvery(deleteTripCardDataRequest.type, deleteTriCardDataSaga);
}