import { createSlice } from "@reduxjs/toolkit";
// import ropSlice from "./ropSlice";

const initialState = {
  ropsignupDetails: {},
  isLoading: false,
  error: "",
  hasData: false,
  codeStatus: "pending",
  ropStatus: {
    status: false,
    message: ""
  }
};
const ROPSlice = createSlice({
  name: "ropSlice",
  initialState,
  reducers: {
    getropPending: state => {
      state.isLoading = true;
      state.codeStatus = "pending";
      state.error = "";
    },
    getropSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.ropsignupDetails = action.payload;
      console.log("rop payload", state.ropsignupDetails, action.payload);
      if (state.ropsignupDetails.code === "I110") {
        console.log("eneter success");
        state.ropStatus.message = "Member Added";
        state.ropStatus.status = true;
      } else {
        console.log("enetred failure");
        console.log("message", state.ropsignupDetails.message);
        if (state.ropsignupDetails.code === "I850") {
          console.log("enter not found");
          state.ropStatus.message = "Duplicate";
          state.ropStatus.status = false;
        } else {
          if (state.ropsignupDetails.code === "I800") {
            state.ropStatus.message = "Email already registered";
            state.ropStatus.status = false;
          }
        }
      }
      console.log('state', state.ropsignupDetails);
      state.codeStatus = "success";
    },
    getropFail: (state, action) => {
      state.isLoading = false;
      state.codeStatus = "failure";
      state.error = action.payload;
    }
  }
});
export const {
  getropPending,
  getropSuccess,
  getropFail
} = ROPSlice.actions;
export default ROPSlice.reducer;