import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  pnrDetails: [],
  isLoading: false,
  hasData: false,
  error: ""
};
const pnrDetailsSlice = createSlice({
  name: "pnrDetails",
  initialState,
  reducers: {
    getTripsPnrDetailsRequest: (state, action) => {
      state.isLoading = true;
      state.error = false;
    },
    getTripsPnrDetailsSuccess: (state, action) => {
      state.isLoading = false;
      state.error = false;
      state.pnrDetails = action.payload;
    },
    getTripsPnrDetailsFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});
export const {
  getTripsPnrDetailsRequest,
  getTripsPnrDetailsSuccess,
  getTripsPnrDetailsFailure
} = pnrDetailsSlice.actions;
export default pnrDetailsSlice.reducer;