import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  baggageDetails: {},
  baggageModalData: {},
  baggageModalError: {},
  seatPreference: {},
  seatPreferenceModal: {},
  seatPreferenceModalError: {},
  isLoading: false,
  error: "",
  hasData: false
};
const extraBaggageAllowanceSlice = createSlice({
  name: "extraBaggageAllowance",
  initialState,
  reducers: {
    fetchBaggageAllowanceRequest: (state, action) => {
      state.isLoading = true;
    },
    fetchBaggageAllowanceSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.baggageDetails = action.payload;
    },
    fetchBaggageAllowanceFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.baggageDetails = {};
    },
    resetBaggageAllowance: state => {
      state.baggageDetails = {};
      state.isLoading = false;
      state.hasData = false;
    },
    getBaggageModalDataSuccess: (state, action) => {
      state.isLoading = false;
      state.baggageModalData = action.payload;
    },
    getBaggageModalDataFailure: (state, action) => {
      state.isLoading = false;
      state.baggageModalError = action.payload;
    },
    fetchSeatPreferenceRequest: (state, action) => {
      state.isLoading = true;
    },
    fetchSeatPreferenceSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.seatPreference = action.payload;
    },
    fetchSeatPreferenceFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.seatPreference = {};
    },
    resetSeatPreference: state => {
      state.seatPreference = {};
      state.isLoading = false;
      state.hasData = false;
    },
    getSeatPreferenceDataSuccess: (state, action) => {
      state.isLoading = false;
      state.seatPreferenceModal = action.payload;
    },
    getSeatPreferenceDataFailure: (state, action) => {
      state.isLoading = false;
      state.seatPreferenceModalError = action.payload;
    }
  }
});
export const {
  fetchBaggageAllowanceRequest,
  fetchBaggageAllowanceSuccess,
  fetchBaggageAllowanceFailure,
  resetBaggageAllowance,
  getBaggageModalDataSuccess,
  getBaggageModalDataFailure,
  fetchSeatPreferenceRequest,
  fetchSeatPreferenceSuccess,
  fetchSeatPreferenceFailure,
  resetSeatPreference,
  getSeatPreferenceDataSuccess,
  getSeatPreferenceDataFailure
} = extraBaggageAllowanceSlice.actions;
export default extraBaggageAllowanceSlice.reducer;