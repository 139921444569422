import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  redeemMilesData: [],
  isLoading: false,
  hasData: false,
  error: ""
};
const redeemMilesSlice = createSlice({
  name: "redeemMiles",
  initialState,
  reducers: {
    redeemMilesRequest: state => {
      state.isLoading = true;
    },
    redeemMilesSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.redeemMilesData = action.payload.data;
    },
    redeemMilesFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});
export const {
  redeemMilesRequest,
  redeemMilesSuccess,
  redeemMilesFailure
} = redeemMilesSlice.actions;
export default redeemMilesSlice.reducer;