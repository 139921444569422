import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  social: {},
  isLoading: false,
  error: "",
  hasData: false
};
const socialSlice = createSlice({
  name: "socialMediaApp",
  initialState,
  reducers: {
    getSocialMediaData: state => {
      state.isLoading = true;
    },
    getSocialMediaDataSucess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.social = action.payload;
    },
    getSocialMediaDataFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});
export const {
  getSocialMediaData,
  getSocialMediaDataSucess,
  getSocialMediaDataFailure
} = socialSlice.actions;
export default socialSlice.reducer;