import { call, put, takeEvery } from "redux-saga/effects";
import { crmResendOTPPending, crmResendOTPSuccess, crmResendOTPFail } from "../slice/crmResendOTPSlice";
import api from "../../../config/api";
import { config } from "../../../config/global";
const API_URL = config.CRM_RESEND_OTP;
const crmResendOTPData = async payload => {
  let data = {
    headers: {
      Authorization: "".concat(sessionStorage.getItem("temptoken")),
      "content-type": "application/json"
    }
  };
  const response = await api.get(API_URL, data);
  sessionStorage.setItem("temptoken", response.headers.authorization);
  return response;
};
function* crmResendOTPSaga(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(crmResendOTPData, payload);
    yield put(crmResendOTPSuccess(response.data));
  } catch (error) {
    yield put(crmResendOTPFail(error));
  }
}
export function* watchCrmResendOTP() {
  yield takeEvery(crmResendOTPPending.type, crmResendOTPSaga);
}