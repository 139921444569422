import { createSlice } from "@reduxjs/toolkit";
// import { FlightListsDataState } from "../models/models";

const initialState = {
  airAwardTstInfo: {},
  isLoading: false,
  hasData: false,
  error: ""
};
const airAwardTstSlice = createSlice({
  name: "airAwardTst",
  initialState,
  reducers: {
    airAwardTstRequest: state => {
      state.isLoading = true;
    },
    airAwardTstSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.airAwardTstInfo = action.payload;
    },
    airAwardTstFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    airAwardTstReset: state => {
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.airAwardTstInfo = {};
    }
  }
});
export const {
  airAwardTstRequest,
  airAwardTstSuccess,
  airAwardTstFailure,
  airAwardTstReset
} = airAwardTstSlice.actions;
export default airAwardTstSlice.reducer;