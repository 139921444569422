import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { getCRMDataPending, getCRMFailure, getCRMSuccess } from "../slice/resetCRMSlice";
const CRM_RESET_URL = "mythai/reset-pwd";
const fetchCRMResetData = async CRMPayload => {
  let crmPayloadData = {
    username: CRMPayload.email
  };
  const response = await api.post(CRM_RESET_URL, crmPayloadData);
  //   if (response.status == 200) {
  //     sessionStorage.setItem("password", RopSignupPayloadData.pinNumber);
  //   }

  return response;
};
function* getCRMData(action) {
  try {
    const response = yield call(fetchCRMResetData, action.payload);
    yield put(getCRMSuccess(response.data));
  } catch (error) {
    yield put(getCRMFailure(error));
  }
}
export function* watchCRMData() {
  yield takeLatest(getCRMDataPending.type, getCRMData);
}