import { call, put, takeEvery } from "redux-saga/effects";
import { flightInfoFail, flightInfoRequest, flightInfoSuccess } from "../slice/flightInfoSlice";
import api from "../../../config/api";
import { config } from "../../../config/global";
const API_URL = config === null || config === void 0 ? void 0 : config.FLIGHT_INFO_API;
const fetchFlightInfo = async payload => {
  let headerData = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken"
    },
    loader: true
  };
  const response = await api.post(API_URL, payload.info, headerData);
  return response;
};
function* fetchFlightInfoSaga(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchFlightInfo, payload);
    yield put(flightInfoSuccess({
      ...response,
      moreInfo: payload.moreInfo,
      id: payload.id
    }));
  } catch (error) {
    yield put(flightInfoFail({
      ...(error === null || error === void 0 ? void 0 : error.response),
      moreInfo: payload.moreInfo,
      id: payload.id
    }));
  }
}
export function* watchFlightInfo() {
  yield takeEvery(flightInfoRequest.type, fetchFlightInfoSaga);
}