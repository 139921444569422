import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  countryList: [],
  isLoading: false,
  error: "",
  hasData: false
};
const preferenceFromSlice = createSlice({
  name: "preferenceFrom",
  initialState,
  reducers: {
    preferenceFromDataRequest: state => {
      state.isLoading = true;
    },
    getPreferenceFromDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      let preference = [];
      preference = action.payload.map(item => ({
        city: item.city,
        country: item.city + "-" + item.country,
        code: item.code,
        airportCityCode: item.airportCityCode
      }));
      state.countryList = preference;
    },
    getPreferenceFromDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});
export const {
  preferenceFromDataRequest,
  getPreferenceFromDataSuccess,
  getPreferenceFromDataFailure
} = preferenceFromSlice.actions;
export default preferenceFromSlice.reducer;