import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  data: {},
  isLoading: false,
  error: "",
  hasData: false,
  codeStatus: "pending",
  success: true,
  session: {
    sessionId: "",
    sequenceNumber: "",
    securityToken: "",
    transactionStatusCode: ""
  }
};
const cancelBookingPNRSlice = createSlice({
  name: "cancelBookingPNRData",
  initialState,
  reducers: {
    cancelBookingPNRRequest: (state, action) => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    cancelBookingPNRSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.data = action.payload;
      state.codeStatus = "success";
    },
    cancelBookingPNRFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.codeStatus = "pending";
    },
    resetCancelBookingPNR: state => {
      state.codeStatus = "pending";
      state.hasData = false;
      state.data = {};
    }
  }
});
export const {
  cancelBookingPNRRequest,
  cancelBookingPNRSuccess,
  cancelBookingPNRFail,
  resetCancelBookingPNR
} = cancelBookingPNRSlice.actions;
export default cancelBookingPNRSlice.reducer;