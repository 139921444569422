import { call, put, takeLatest } from "redux-saga/effects";
// import api from "../config/api";
import axios from "axios";
import { getPreferenceError, getPreferenceRequest, getPreferenceSuccess } from "../slice/preferenceSlice";

/// Temporarily url as data saved in json till service is ready.
const API_URL = "/assets/json-data/preference.json";

/// get data generator function
function* getPreferences() {
  try {
    const response = yield call(axios.get, API_URL, {
      baseURL: "/"
    });
    yield put(getPreferenceSuccess(response.data));
  } catch (error) {
    yield put(getPreferenceError(error.message));
  }
}
export function* watchPreferences() {
  yield takeLatest(getPreferenceRequest.type, getPreferences);
}