import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { newsDataRequest, newsDataSuccess, newsDataFailure } from "../slice/newsSlice";
const API_URL = "./json/news.json";
function* getNewsData() {
  try {
    const response = yield call(axios.get, API_URL, {
      baseURL: "/"
    });
    yield put(newsDataSuccess(response.data));
  } catch (error) {
    yield put(newsDataFailure(error.message));
  }
}
export function* watchNewsData() {
  yield takeLatest(newsDataRequest.type, getNewsData);
}