import { call, put, takeLatest } from "redux-saga/effects";
import { countryDataRequest, getCountryDataFailure, getCountryDataSuccess } from "../../slice/header/countrySlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
//API Call to fetch list of countries
const data = {
  mode: "no-cors",
  headers: {
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*"
  },
  body: {}
};
const fetchCountryData = async () => {
  const COUNTRY_API_URL = config.COUNTRY_LIST;
  const response = await api.get(COUNTRY_API_URL, data);
  return response;
};
function* getCountryData() {
  try {
    const response = yield call(fetchCountryData);
    yield put(getCountryDataSuccess(response.data));
  } catch (error) {
    yield put(getCountryDataFailure(error.message));
  }
}
export function* watchCountryData() {
  yield takeLatest(countryDataRequest.type, getCountryData);
}