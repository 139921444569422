import { call, put, takeLatest } from "redux-saga/effects";
import { fetchLifeStyleRequest, getLifeStyleSuccess, getLifeStyleFailure } from "../../slice/fetchLifeStyleSlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
const data = {
  mode: "no-cors",
  headers: {
    // memberId: sessionStorage.getItem("memberId"),
    // Authorization: sessionStorage.getItem("accesstoken"),
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*"
  }
};
const fetchMyLifeStyleDataData = async () => {
  const FETCH_MY_LIFESTYLE_API_URL = config.FETCH_MY_LIFESTYLE;
  const response = await api.get(FETCH_MY_LIFESTYLE_API_URL, data);
  console.log("fetchMyLifeStyleDataData", response);
  return response;
};
function* getLifeStyleData() {
  try {
    const response = yield call(fetchMyLifeStyleDataData);
    yield put(getLifeStyleSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(getLifeStyleFailure(error.message));
  }
}
export function* watchMyLifeStyleData() {
  yield takeLatest(fetchLifeStyleRequest.type, getLifeStyleData);
}