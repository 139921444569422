import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { config } from "../../../config/global";
import { getProfileContactInfoPending, getProfileContactInfoSuccess, getProfileContactInfoFailure, getCRMProfileContactInfoPending, getCRMProfileContactInfoSuccess, getCRMProfileContactInfoFailure } from "../slice/profileContactInfoSlice";
const PROFILE_CONTACT_INFO_API_URL = config.PROFILE_CONTACT_UPDATE;
const CRM_PROFILE_CONTACT_INFO_API_URL = config.CRM_PROFILE_UPDATE;
const fetchProfileContactInfoData = async ProfileContactInfoPayload => {
  var _ProfileContactInfoPa;
  let headerData = {
    headers: {
      memberId: sessionStorage.getItem("memberId"),
      Authorization: localStorage.getItem("accesstoken"),
      "Content-Type": "application/json"
    },
    isGenericErr: true
  };
  let profileContactInfoPayloadData = {
    updateCommunicationFlag: "Y",
    address: {
      addressType: ProfileContactInfoPayload.addressType,
      addressLine1: ProfileContactInfoPayload.addressLine1,
      ...(ProfileContactInfoPayload.addressLine2 && {
        addressLine2: ProfileContactInfoPayload.addressLine2
      }),
      ...(ProfileContactInfoPayload.state && {
        state: ProfileContactInfoPayload.state
      }),
      city: ProfileContactInfoPayload.city,
      countryCode: ProfileContactInfoPayload.countryCodeSecondary,
      postalCode: ProfileContactInfoPayload.postalCode
    },
    phone: {
      countryCode: ProfileContactInfoPayload.countryCode,
      areaCode: ProfileContactInfoPayload.areaCode,
      number: (_ProfileContactInfoPa = ProfileContactInfoPayload.contactNumber) === null || _ProfileContactInfoPa === void 0 ? void 0 : _ProfileContactInfoPa.replaceAll("-", ""),
      seqNo: ProfileContactInfoPayload.seqNo
    },
    emailAddress: ProfileContactInfoPayload.email
  };
  const response = await api.post(PROFILE_CONTACT_INFO_API_URL, profileContactInfoPayloadData, headerData);
  // if (response.status == 200) {
  //   sessionStorage.setItem("password", profileContactInfoPayloadData.pinNumber);
  //   console.log("fetchProfileContactInfoData_rESP",response.status)
  // }

  return response;
};
const fetchCRMProfileContactInfoData = async CRMProfileContactInfoPayload => {
  let headerData = {
    headers: {
      Authorization: localStorage.getItem("accesstoken"),
      "Content-Type": "application/json"
    },
    isGenericErr: true
  };
  let crmProfileContactInfoPayloadData = {
    countryCode: CRMProfileContactInfoPayload.countryCode,
    mobileNumber: CRMProfileContactInfoPayload.contactNumber
  };
  const response = await api.post(CRM_PROFILE_CONTACT_INFO_API_URL, crmProfileContactInfoPayloadData, headerData);
  return response;
};
function* submitCRMProfileContactInfoData(action) {
  try {
    const response = yield call(fetchCRMProfileContactInfoData, action.payload);
    yield put(getCRMProfileContactInfoSuccess(response.data));
  } catch (error) {
    yield put(getCRMProfileContactInfoFailure(error));
  }
}
function* submitProfileContactInfoData(action) {
  try {
    const response = yield call(fetchProfileContactInfoData, action.payload);
    yield put(getProfileContactInfoSuccess(response.data));
    console.log("response", response.data);
  } catch (error) {
    yield put(getProfileContactInfoFailure(error));
  }
}
export function* watchProfileContactInfoData() {
  yield takeLatest(getProfileContactInfoPending.type, submitProfileContactInfoData);
}
export function* watchCRMProfileContactInfoData() {
  yield takeLatest(getCRMProfileContactInfoPending.type, submitCRMProfileContactInfoData);
}