import { call, put, takeEvery } from "redux-saga/effects";
import { airAwardPaymentInitRequest, airAwardPaymentInitSuccess, airAwardPaymentInitFailure } from "../slice/airAwardPaymentInitSlice";
import api from "../../../config/api";
import { config } from "../../../config/global";
const API_URL = config === null || config === void 0 ? void 0 : config.AIR_AWARD_PAYMENT_INIT;
const fetchAirAwardPaymentInitDetail = async payload => {
  let headerData = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken"
    },
    loader: true
  };
  const response = await api.post(API_URL, payload, headerData);
  return response;
};
function* fetchAirAwardPaymentInit(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchAirAwardPaymentInitDetail, payload);
    yield put(airAwardPaymentInitSuccess(response));
  } catch (error) {
    yield put(airAwardPaymentInitFailure(error === null || error === void 0 ? void 0 : error.response));
  }
}
export function* watchAirAwardPaymentInit() {
  yield takeEvery(airAwardPaymentInitRequest.type, fetchAirAwardPaymentInit);
}