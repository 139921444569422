import { call, put, takeEvery } from "redux-saga/effects";
import { termsPrivacyModalDataRequest, getTermsPrivacyModalSuccess, getTermsPrivacyModalFailure, nationDataRequest, getNationDataSuccess, getNationDataFailure } from "../slice/signUpSlice";
import axios from "axios";
const MODAL_API_URL = "./json/termsPrivacy.json";
const COUNTRY_API_URL = "./json/country-nationality-code-flag.json";
function* getTermsPrivacyModalData() {
  try {
    const response = yield call(axios.get, MODAL_API_URL, {
      baseURL: "/"
    });
    yield put(getTermsPrivacyModalSuccess(response.data));
  } catch (error) {
    yield put(getTermsPrivacyModalFailure(error.message));
  }
}
function* getNationData() {
  try {
    const response = yield call(axios.get, COUNTRY_API_URL, {
      baseURL: "/"
    });
    yield put(getNationDataSuccess(response.data));
  } catch (error) {
    yield put(getNationDataFailure(error.message));
  }
}
export function* watchTermsPrivacyModalData() {
  yield takeEvery(termsPrivacyModalDataRequest.type, getTermsPrivacyModalData);
}
export function* watchNationData() {
  yield takeEvery(nationDataRequest.type, getNationData);
}