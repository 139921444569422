import { call, put, takeLatest } from "redux-saga/effects";
import { cancelCheckinRequest, cancelCheckinSuccess, cancelCheckinFailure } from "../../slice/cancelCheckin/cancelCheckinSlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
import i18n from "../../../../i18n";
const fetchcancelCheckin = async cancelCheckin => {
  const API_URL = config.DAPI_CANCEL_CHECKIN;
  const data = {
    mode: "no-cors",
    headers: {
      Authorization: sessionStorage.getItem("checkintoken"),
      "Content-Type": "application/json",
      "Accept-Language": "".concat(i18n.language)
    },
    loader: true
  };
  console.log("cancel checkin :", cancelCheckin);
  let cancelAllCheckinPayload = {
    journeyId: cancelCheckin === null || cancelCheckin === void 0 ? void 0 : cancelCheckin.journeyId,
    isCancelAll: cancelCheckin === null || cancelCheckin === void 0 ? void 0 : cancelCheckin.isCancelAll
  };
  let cancelCheckinPayload = {
    journeyId: cancelCheckin === null || cancelCheckin === void 0 ? void 0 : cancelCheckin.journeyId,
    journeyElementIds: cancelCheckin === null || cancelCheckin === void 0 ? void 0 : cancelCheckin.travelerIds,
    isCancelAll: cancelCheckin === null || cancelCheckin === void 0 ? void 0 : cancelCheckin.isCancelAll
  };
  let payload = (cancelCheckin === null || cancelCheckin === void 0 ? void 0 : cancelCheckin.travelerIds) !== null ? cancelCheckinPayload : cancelAllCheckinPayload;
  const response = await api.post(API_URL, payload, data);
  return response;
};
function* getcancelCheckin(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchcancelCheckin, payload);
    yield put(cancelCheckinSuccess(response.data));
  } catch (error) {
    yield put(cancelCheckinFailure(error));
  }
}
export function* watchcancelCheckin() {
  yield takeLatest(cancelCheckinRequest.type, getcancelCheckin);
}