import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  globalSearchDetails: {},
  isLoading: true,
  error: "",
  hasData: false
};
const globalSearchSlice = createSlice({
  name: "globalSearch",
  initialState,
  reducers: {
    getGlobalSearchDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.globalSearchDetails = action.payload;
    },
    getGlobalSearchDataFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});
export const {
  getGlobalSearchDataSuccess,
  getGlobalSearchDataFailure
} = globalSearchSlice.actions;
export default globalSearchSlice.reducer;