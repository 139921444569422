import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  termsConditionData: "",
  reviewPayment: {
    isPayment: false,
    currency: "",
    amount: "",
    paymentMode: ""
  }
};
const mileagePurchaseSlice = createSlice({
  name: "mileagePurchaseSlice",
  initialState,
  reducers: {
    mileageTermsConditionSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.termsConditionData = action.payload;
      state.termsConditionError = "";
    },
    mileageTermsConditionFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.termsConditionError = action.payload;
    },
    mileageStatus: (state, action) => {
      state.reviewPayment = {
        ...(state === null || state === void 0 ? void 0 : state.reviewPayment),
        ...(action === null || action === void 0 ? void 0 : action.payload)
      };
    }
  }
});
export const {
  mileageTermsConditionSuccess,
  mileageTermsConditionFailure,
  mileageStatus
} = mileagePurchaseSlice.actions;
export default mileagePurchaseSlice.reducer;