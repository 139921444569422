import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  nomineeData: {},
  addNominee: {},
  removeNomineeDetails: {},
  addNomineeError: "",
  removeNomineeError: {},
  isLoading: false,
  error: "",
  hasData: false,
  removeNomineeStatus: {
    status: false,
    message: ""
  },
  nomineeTermsData: {},
  nomineeTermsError: "",
  nomineeTabState: {},
  paidInsert: false,
  memberIcon: ""
};
const fetchNomineeSlice = createSlice({
  name: "nominee",
  initialState,
  reducers: {
    fetchNomineeRequest: state => {
      state.isLoading = true;
    },
    getNomineeSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.nomineeData = action.payload;
    },
    getNomineeFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.nomineeData = {};
    },
    addNomineeRequest: (state, action) => {
      state.isLoading = true;
    },
    addNomineeSuccess: (state, action) => {
      state.isLoading = false;
      state.addNomineeError = "";
      state.addNominee = action.payload;
    },
    addNomineeFailure: (state, action) => {
      state.addNomineeError = action.payload;
      state.isLoading = false;
      state.addNominee = {};
    },
    resetAddNomineeDetails: state => {
      state.addNominee = {};
      state.addNomineeError = "";
    },
    removeNomineeRequest: (state, action) => {
      state.isLoading = true;
    },
    removeNomineeSuccess: (state, action) => {
      state.isLoading = false;
      state.removeNomineeError = "";
      state.hasData = true;
      state.removeNomineeDetails = action.payload;
      state.removeNomineeStatus.status = true;
    },
    removeNomineeFailure: (state, action) => {
      state.removeNomineeError = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.removeNomineeDetails = {};
      state.removeNomineeStatus.status = false;
    },
    resetRemoveNomineeDetails: state => {
      state.removeNomineeDetails = {};
      state.isLoading = false;
      state.removeNomineeError = {};
      state.hasData = false;
      state.removeNomineeStatus = {
        status: false,
        message: ""
      };
    },
    getNomineeTermsRequest: state => {
      state.isLoading = true;
    },
    getNomineeTermsSuccess: (state, action) => {
      state.isLoading = false;
      state.nomineeTermsError = "";
      state.hasData = true;
      state.nomineeTermsData = action.payload;
    },
    getNomineeTermsFailure: (state, action) => {
      state.nomineeTermsError = action.payload;
      state.isLoading = false;
    },
    updateNomineeTabState: (state, action) => {
      state.nomineeTabState = action.payload;
    },
    setPaidInsertState: (state, action) => {
      state.paidInsert = action.payload;
    },
    setMemberIconState: (state, action) => {
      state.memberIcon = action.payload;
    }
  }
});
export const {
  fetchNomineeRequest,
  getNomineeSuccess,
  getNomineeFailure,
  addNomineeRequest,
  addNomineeSuccess,
  addNomineeFailure,
  resetAddNomineeDetails,
  removeNomineeRequest,
  removeNomineeSuccess,
  removeNomineeFailure,
  resetRemoveNomineeDetails,
  getNomineeTermsRequest,
  getNomineeTermsSuccess,
  getNomineeTermsFailure,
  updateNomineeTabState,
  setPaidInsertState,
  setMemberIconState
} = fetchNomineeSlice.actions;
export default fetchNomineeSlice.reducer;