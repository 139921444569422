import { createSlice } from "@reduxjs/toolkit";
// import { FlightListsDataState } from "../models/models";

const initialState = {
  airAwardPnrInfo: {},
  isLoading: false,
  hasData: false,
  error: ""
};
const airAwardPnrSlice = createSlice({
  name: "airAwardPnr",
  initialState,
  reducers: {
    airAwardPnrRequest: state => {
      state.isLoading = true;
    },
    airAwardPnrSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.airAwardPnrInfo = action.payload;
    },
    airAwardPnrFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    airAwardPnrReset: state => {
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.airAwardPnrInfo = {};
    }
  }
});
export const {
  airAwardPnrRequest,
  airAwardPnrSuccess,
  airAwardPnrFailure,
  airAwardPnrReset
} = airAwardPnrSlice.actions;
export default airAwardPnrSlice.reducer;