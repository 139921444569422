import api from "../../../config/api";
import { config } from "../../../config/global";
import { call, put, takeLatest } from "redux-saga/effects";
import { getropFail, getropPending, getropSuccess } from "../slice/ropSinUpSlice";

//API Call to fetch list of countries
const data = {
  mode: "no-cors",
  headers: {
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*"
  },
  body: {}
};
const fetchsignUpRopData = async () => {
  const ROP_API_URL = config.ROP;
  const response = await api.get(ROP_API_URL, data);
  return response;
};
function* getropData() {
  try {
    const response = yield call(fetchsignUpRopData);
    yield put(getropSuccess(response.data));
  } catch (error) {
    yield put(getropFail(error.message));
  }
}
export function* watchropData() {
  yield takeLatest(getropPending.type, getropData);
}