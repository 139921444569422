import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  myprofilMilesData: {},
  isLoading: false,
  error: "",
  hasData: false
};
const fetchMainProfileMilesSlice = createSlice({
  name: "MyProfileMilesDeails",
  initialState,
  reducers: {
    fetchMyProfileMilesRequest: state => {
      state.isLoading = true;
    },
    getMyProfileMilesSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.myprofileMilesData = action.payload;
    },
    getMyProfileMilesFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.myprofileMilesData = {};
    }
  }
});
export const {
  fetchMyProfileMilesRequest,
  getMyProfileMilesSuccess,
  getMyProfileMilesFailure
} = fetchMainProfileMilesSlice.actions;
export default fetchMainProfileMilesSlice.reducer;