import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  resetCRMDetails: {},
  isLoading: false,
  error: {},
  hasData: false,
  codeStatus: "pending",
  crmstatus: {
    status: false,
    message: ""
  }
};
const crmResetSlice = createSlice({
  name: "crmReset",
  initialState,
  reducers: {
    getCRMDataPending: (state, action) => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    getCRMSuccess: (state, action) => {
      console.log("Action playload", action.payload);
      state.isLoading = false;
      state.hasData = true;
      state.resetCRMDetails = action.payload;
      state.codeStatus = "success";
    },
    getCRMFailure: (state, action) => {
      state.isLoading = false;
      state.codeStatus = "failure";
      state.error = action.payload.response.data;
      if (state.error.Code === "400") {
        state.crmstatus.message = "Invalid Email";
        state.crmstatus.status = false;
      }
    },
    resetCRMData: state => {
      state.codeStatus = "pending";
      state.error = {};
      state.isLoading = false;
      state.hasData = false;
      state.resetCRMDetails = {};
    }
  }
});
export const {
  getCRMDataPending,
  getCRMFailure,
  getCRMSuccess,
  resetCRMData
} = crmResetSlice.actions;
export default crmResetSlice.reducer;