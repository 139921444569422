import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  AdvertisementDetails: [],
  isLoading: false,
  error: "",
  hasData: false
};
const advertisementSlice = createSlice({
  name: "advertisement",
  initialState,
  reducers: {
    advertisementDataRequest: state => {
      state.isLoading = true;
    },
    getAdvertisementDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.AdvertisementDetails = action.payload;
    },
    getAdvertisementDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});
export const {
  advertisementDataRequest,
  getAdvertisementDataSuccess,
  getAdvertisementDataFailure
} = advertisementSlice.actions;
export default advertisementSlice.reducer;