export const constant = {
  userName: "",
  clientIdTestGoogle: "997068794039-45jd38gm2bk7k7fb89ps2ne9or311tsm.apps.googleusercontent.com",
  clientIdGoogle: "103388376992-2gigmnbjla91p3ecgmje2276rcj6im37.apps.googleusercontent.com",
  //clientId:"997068794039-45jd38gm2bk7k7fb89ps2ne9or311tsm.apps.googleusercontent.com",
  //clientIdGoogleCRMDev: "103388376992-2gigmnbjla91p3ecgmje2276rcj6im37.apps.googleusercontent.com",
  //clientIdGoogleCRMProd: "1035301278821-t6oicoe1j3f7knut4o87n06i4vutip3c.apps.googleusercontent.com",
  appIdFb: "488806647206287",
  appIdFbCRM: "1446709515630218",
  //appIdFb: "488806647206287",
  //facebookAppidCRMDev:"1446709515630218",
  //facebookAppidCRMProd:"6116037258451278"
  ruleset_regional_backtrack: "A",
  ruleset_regional_non_backtrack: "B",
  ruleset_regional_non_thai_intercontinental: "C",
  "ruleset_non-regional_international": "D",
  ruleset_domestic_international: "E",
  ruleset_intercontinental_non_thai: "F",
  ruleset_origin_bkk: "G",
  contact_mail: "digital-accounting@thaiairways.com",
  review_info_silver: "Silver",
  review_info_gold: "Gold",
  review_info_platinum: "Platinum",
  review_info_member: "Member",
  baggage_info: "*Or 1 additional piece when the piece concept applies.",
  baggage_text: "If your journey involves multiple airline partners, please be aware that baggage rules may vary. In case of code-share flights that include a point in the United States/US Territories, the carrier performing as the marketing carrier determines the baggage rule. In some cases, the baggage rules that apply for your journey may be those of our partner airlines, please contact your travel agent or our ticketing/reservation agent for further advice."
};
//TODO: to change to TG provided client id and facebook app id. This is developer created for testing