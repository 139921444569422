import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  services: [],
  isLoading: false,
  hasData: false,
  error: ""
};
const AdditionalServiceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    additionalServiceDataRequest: state => {
      state.isLoading = true;
    },
    getAdditionalServiceDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.service = action.payload;
    },
    getAdditionalServiceDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});
export const {
  additionalServiceDataRequest,
  getAdditionalServiceDataSuccess,
  getAdditionalServiceDataFailure
} = AdditionalServiceSlice.actions;
export default AdditionalServiceSlice.reducer;