import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  profileData: {},
  crmProfileData: {},
  isLoading: false,
  error: "",
  hasData: false,
  countryData: {},
  cityData: {},
  stateData: {}
};
const fetchProfileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    fetchProfileRequest: state => {
      state.isLoading = true;
    },
    getProfileSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.profileData = action.payload;
    },
    clearProfileData: state => {
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.profileData = {};
    },
    getProfileFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.profileData = {};
    },
    fetchCRMProfileRequest: state => {
      state.isLoading = true;
    },
    getCRMProfileSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.crmProfileData = action.payload;
    },
    getCRMProfileFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.crmProfileData = {};
    },
    fetchCountryRequest: state => {
      state.isLoading = true;
    },
    getCountrySuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.countryData = action.payload;
    },
    getCountryFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.countryData = {};
    },
    fetchCityRequest: state => {
      state.isLoading = true;
    },
    getCitySuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.cityData = action.payload;
    },
    getCityFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.cityData = {};
    },
    fetchStateRequest: state => {
      state.isLoading = true;
    },
    getStateSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.stateData = action.payload;
    },
    getStateFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.stateData = {};
    }
  }
});
export const {
  fetchProfileRequest,
  getProfileSuccess,
  getProfileFailure,
  fetchCRMProfileRequest,
  getCRMProfileSuccess,
  getCRMProfileFailure,
  fetchCountryRequest,
  getCountrySuccess,
  getCountryFailure,
  fetchCityRequest,
  getCitySuccess,
  getCityFailure,
  fetchStateRequest,
  getStateSuccess,
  getStateFailure,
  clearProfileData
} = fetchProfileSlice.actions;
export default fetchProfileSlice.reducer;