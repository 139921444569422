import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  pnrDetails: [],
  isLoading: false,
  hasData: false,
  error: "",
  pnr: "",
  tripCardStatus: {
    status: false,
    message: ""
  }
};
const deleteTripCardSlice = createSlice({
  name: "deleteTripCard",
  initialState,
  reducers: {
    deleteTripCardDataRequest: (state, action) => {
      state.isLoading = true;
      state.pnr = action.payload;
      state.hasData = false;
    },
    deleteTripCardDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.upcomingTrips = action.payload;
      state.tripCardStatus.message = "Trip has been deleted";
      state.tripCardStatus.status = true;
    },
    deleteTripCardDataFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.tripCardStatus.status = false;
    },
    resetTripCardState: state => {
      state.tripCardStatus.status = false;
    }
  }
});
export const {
  deleteTripCardDataRequest,
  deleteTripCardDataSuccess,
  deleteTripCardDataFailure,
  resetTripCardState
} = deleteTripCardSlice.actions;
export default deleteTripCardSlice.reducer;