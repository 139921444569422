import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  ropMemberJoinData: null,
  isLoading: false,
  error: "",
  hasData: false
};
const ropMemberJoinSlice = createSlice({
  name: "ropmemberjoin",
  initialState,
  reducers: {
    getROPMemberJoinDataRequest: state => {
      state.isLoading = true;
    },
    getROPMemberJoinDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.ropMemberJoinData = action.payload;
    },
    getROPMemberJoinDataError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});
export const {
  getROPMemberJoinDataRequest,
  getROPMemberJoinDataSuccess,
  getROPMemberJoinDataError
} = ropMemberJoinSlice.actions;
export default ropMemberJoinSlice.reducer;