import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  IdCardDetails: [],
  countryList: [],
  isLoading: false,
  error: "",
  hasData: false
};
const languageSlice = createSlice({
  name: "languages",
  initialState,
  reducers: {
    languageDataRequest: state => {
      state.isLoading = true;
    },
    getlanguageDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.countryList = action.payload;
    },
    getlanguageDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});
export const {
  languageDataRequest,
  getlanguageDataSuccess,
  getlanguageDataFailure
} = languageSlice.actions;
export default languageSlice.reducer;