import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  ropDetails: [],
  isLoading: false,
  error: "",
  hasData: false
};
const ropSlice = createSlice({
  name: "rop",
  initialState,
  reducers: {
    getROPDataRequest: state => {
      state.isLoading = true;
    },
    getROPDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.ropDetails = action.payload;
    },
    getROPDataFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});
export const {
  getROPDataRequest,
  getROPDataSuccess,
  getROPDataFail
} = ropSlice.actions;
export default ropSlice.reducer;