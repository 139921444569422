import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  resendOTPDetails: {},
  isLoading: false,
  error: "",
  hasData: false,
  codeStatus: "pending"
};
const resendOTPSlice = createSlice({
  name: "resendSection",
  initialState,
  reducers: {
    resendOTPPending: (state, action) => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    resendOTPSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.resendOTPDetails = action.payload;
      state.codeStatus = "success";
    },
    resendOTPFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.codeStatus = "failure";
      state.hasData = false;
    },
    resendOTPClear: state => {
      console.log("test otp clear", state);
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.resendOTPDetails = {};
      state.codeStatus = "pending";
    }
  }
});
export const {
  resendOTPPending,
  resendOTPSuccess,
  resendOTPFail,
  resendOTPClear
} = resendOTPSlice.actions;
export default resendOTPSlice.reducer;