import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  partnerDetails: [],
  isLoading: false,
  error: "",
  hasData: false
};
const partnerSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {
    partnerDataRequest: state => {
      state.isLoading = true;
    },
    getPartnerDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.partnerDetails = action.payload;
    },
    getPartnerDataFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});
export const {
  partnerDataRequest,
  getPartnerDataSuccess,
  getPartnerDataFailure
} = partnerSlice.actions;
export default partnerSlice.reducer;