import { call, put, takeLatest } from "redux-saga/effects";
import { fetchCrmMemberProfileRequest, getCrmProfileSuccess, getCrmProfileFailure, fetchCRMCountryCodeRequest, getCRMCountryCodeSuccess, getCRMCountryCodeFailure, fetchCRMnewsletterPreferenceRequest, getCRMnewsletterPreferenceSuccess, getCRMnewsletterPreferenceFailure, fetchCRMresidenceRequest, getCRMresidenceSuccess, getCRMresidenceFailure } from "../../slice/fetchCrmProfileSlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
const data = {
  mode: "no-cors",
  headers: {
    Authorization: localStorage.getItem("accesstoken")
  }
};
const fetchCrmProfileData = async () => {
  const FETCH_CRM_MEMBER_PROFILE_API_URL = config.FETCH_CRM_MEMBER_PROFILE;
  const response = await api.get(FETCH_CRM_MEMBER_PROFILE_API_URL, data);
  return response;
};
function* getProfileData() {
  try {
    const response = yield call(fetchCrmProfileData);
    yield put(getCrmProfileSuccess(response.data));
  } catch (error) {
    yield put(getCrmProfileFailure(error.message));
  }
}
const fetchCRMCountryCodeData = async () => {
  const FETCH_CRM_CountryCode = config.CRM_COUNTRYCODE_JSON;
  const response = await api.get(FETCH_CRM_CountryCode, data);
  return response;
};
function* getCRMCountryCodeData() {
  try {
    const response = yield call(fetchCRMCountryCodeData);
    yield put(getCRMCountryCodeSuccess(response.data));
  } catch (error) {
    yield put(getCRMCountryCodeFailure(error.message));
  }
}
const fetchCRMnewsletterPreferenceData = async () => {
  const FETCH_CRM_newsletterPreference = config.CRM_NEWSLETTERPREFERENCE_JSON;
  const response = await api.get(FETCH_CRM_newsletterPreference, data);
  return response;
};
function* getCRMnewsletterPreferenceData() {
  try {
    const response = yield call(fetchCRMnewsletterPreferenceData);
    yield put(getCRMnewsletterPreferenceSuccess(response.data));
  } catch (error) {
    yield put(getCRMnewsletterPreferenceFailure(error.message));
  }
}
const fetchCRMresidenceData = async () => {
  const FETCH_CRM_residence = config.CRM_RESIDENCE_JSON;
  const response = await api.get(FETCH_CRM_residence, data);
  return response;
};
function* getCRMresidenceData() {
  try {
    const response = yield call(fetchCRMresidenceData);
    yield put(getCRMresidenceSuccess(response.data));
  } catch (error) {
    yield put(getCRMresidenceFailure(error.message));
  }
}
export function* watchCrmresidenceData() {
  yield takeLatest(fetchCRMresidenceRequest.type, getCRMresidenceData);
}
export function* watchCrmnewsletterPreferenceData() {
  yield takeLatest(fetchCRMnewsletterPreferenceRequest.type, getCRMnewsletterPreferenceData);
}
export function* watchCrmCountryCodeData() {
  yield takeLatest(fetchCRMCountryCodeRequest.type, getCRMCountryCodeData);
}
export function* watchCrmMemberProfileData() {
  yield takeLatest(fetchCrmMemberProfileRequest.type, getProfileData);
}