import { createSlice } from "@reduxjs/toolkit";
// import { useLocation, useNavigate } from "react-router-dom";
// import { useTranslation } from "react-i18next";

const initialState = {
  logoutDetails: {},
  crmLogoutData: {},
  isLoading: false,
  error: false,
  codeStatus: "pending",
  navigateRop: false
};
function handleLogoutState(logoutDetails, codeStatus, navigate) {
  if (codeStatus === "success") {
    if (logoutDetails.code === "200" || logoutDetails !== null && logoutDetails !== void 0 && logoutDetails.success) {
      if (navigate) {
        var _localStorage, _localStorage2, _localStorage3;
        sessionStorage.clear();
        (_localStorage = localStorage) === null || _localStorage === void 0 || _localStorage.removeItem("accesstoken");
        (_localStorage2 = localStorage) === null || _localStorage2 === void 0 || _localStorage2.removeItem("isROPLoggedIn");
        (_localStorage3 = localStorage) === null || _localStorage3 === void 0 || _localStorage3.removeItem("isCRMLoggedIn");
        sessionStorage.setItem("navigateRop", "true");
        window.location.reload();
      } else {
        var _localStorage4, _localStorage5, _localStorage6;
        sessionStorage.clear();
        (_localStorage4 = localStorage) === null || _localStorage4 === void 0 || _localStorage4.removeItem("accesstoken");
        (_localStorage5 = localStorage) === null || _localStorage5 === void 0 || _localStorage5.removeItem("isROPLoggedIn");
        (_localStorage6 = localStorage) === null || _localStorage6 === void 0 || _localStorage6.removeItem("isCRMLoggedIn");
        window.location.reload();
      }
    }
  } else if (codeStatus === "failure") {
    var _localStorage7, _localStorage8, _localStorage9;
    sessionStorage.clear();
    (_localStorage7 = localStorage) === null || _localStorage7 === void 0 || _localStorage7.removeItem("accesstoken");
    (_localStorage8 = localStorage) === null || _localStorage8 === void 0 || _localStorage8.removeItem("isROPLoggedIn");
    (_localStorage9 = localStorage) === null || _localStorage9 === void 0 || _localStorage9.removeItem("isCRMLoggedIn");
    window.location.reload();
  }
}
const LogOutSlice = createSlice({
  name: "logout",
  initialState,
  reducers: {
    logoutRequest: state => {
      state.isLoading = true;
      state.error = null;
      state.codeStatus = "pending";
    },
    getLogOutSuccess: (state, action) => {
      state.logoutDetails = action.payload;
      state.isLoading = false;
      state.codeStatus = "success";
      handleLogoutState(action.payload, "success", state.navigateRop);
    },
    getLogOutFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.codeStatus = "failure";
      handleLogoutState(action.payload, "failure");
    },
    crmLogoutRequest: state => {
      state.isLoading = true;
      state.error = null;
      state.codeStatus = "pending";
    },
    crmGetLogOutSuccess: (state, action) => {
      state.crmLogoutData = action.payload;
      state.isLoading = false;
      state.codeStatus = "success";
      handleLogoutState(action.payload, "success");
    },
    crmGetLogOutFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.codeStatus = "failure";
      handleLogoutState(action.payload, "failure");
    },
    setNavigateRop: (state, action) => {
      state.navigateRop = action.payload;
    }
  }
});
export const {
  logoutRequest,
  getLogOutSuccess,
  getLogOutFailure,
  crmLogoutRequest,
  crmGetLogOutSuccess,
  crmGetLogOutFailure,
  setNavigateRop
} = LogOutSlice.actions;
export default LogOutSlice.reducer;