export default function isAuthenticate() {
  function checkToken() {
    try {
      const accessToken = localStorage.getItem("accesstoken");
      return accessToken !== null;
    } catch (error) {
      console.error("Error accessing access token:", error);
      return false;
    }
  }
  const hasAccessToken = checkToken();
  if (hasAccessToken) {
    return true;
  } else {
    return false;
  }
}