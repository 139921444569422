import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  IdCardDetails: [],
  countryList: [],
  isLoading: false,
  error: "",
  hasData: false
};
const idCarouselSlice = createSlice({
  name: "idCarousel",
  initialState,
  reducers: {
    idCardDataRequest: state => {
      state.isLoading = true;
    },
    getIdCardDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.IdCardDetails = action.payload;
    },
    getIdCardDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});
export const {
  idCardDataRequest,
  getIdCardDataSuccess,
  getIdCardDataFailure
} = idCarouselSlice.actions;
export default idCarouselSlice.reducer;