import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  upcomingTrips: {},
  memberId: "",
  pnrDetails: [],
  isLoading: false,
  hasData: false,
  error: ""
};
const upcomingTripSlice = createSlice({
  name: "upcomingTrips",
  initialState,
  reducers: {
    tripDataRequest: state => {
      state.isLoading = true;
    },
    getTripDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.upcomingTrips = action.payload;
    },
    getTripDataFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateTripsPnrDetailsRequest: (state, action) => {
      state.isLoading = true;
      state.error = false;
    },
    updateTripsPnrDetailsSuccess: (state, action) => {
      const {
        payload,
        index
      } = action.payload;
      state.isLoading = false;
      state.error = false;
      state.upcomingTrips.pnrDetails[index].flightDetails = payload;
    },
    updateTripsPnrDetailsFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});
export const {
  tripDataRequest,
  getTripDataSuccess,
  getTripDataFailure,
  updateTripsPnrDetailsRequest,
  updateTripsPnrDetailsFailure,
  updateTripsPnrDetailsSuccess
} = upcomingTripSlice.actions;
export default upcomingTripSlice.reducer;