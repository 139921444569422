import api from "../../../config/api";
import { call, put, takeEvery } from "redux-saga/effects";
import cmsApi from "../../../config/cmsApi";
import { config } from "../../../config/global";
export const FETCH_DATA_CONSTANT = "FETCH_DATA";
function* fetchData(action) {
  try {
    //const { url, method = 'GET', requestTransform } = action;
    //const transformedData = requestTransform? requestTransform({}) : undefined;
    const response = yield call(action.isTeamsite ? cmsApi.get : api.get, action.url); // ToDo: Need to evaluate whether config param (3rd one) to be passed as it can be handled in interceptor
    yield put(action.successAction(response.data));
  } catch (error) {
    yield put(action.errorAction(error));
  }
}
export function* watchFetchData() {
  yield takeEvery(config.FETCH_DATA_CONSTANT, fetchData);
}
export default watchFetchData;