import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoading: false,
  hasData: false,
  termsConditionData: "",
  termsConditionError: "",
  transferMilesPaymentInitInfo: {},
  reviewSummary: {},
  reviewSummaryStatus: false,
  error: "",
  isError: false
};
const transferMilesSlice = createSlice({
  name: "transferMilesSlice",
  initialState,
  reducers: {
    transferMilesTermsConditionRequest: state => {
      state.isLoading = true;
    },
    transferMilesTermsConditionSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.termsConditionData = action.payload;
      state.termsConditionError = "";
    },
    transferMilesTermsConditionFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.termsConditionError = action.payload;
    },
    resetTransferMilesTermsCondition: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.termsConditionData = {};
      state.termsConditionError = "";
    },
    reviewTransferMilesRequest: state => {
      state.isLoading = true;
    },
    reviewTransferMilesSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.reviewSummary = action.payload;
      state.reviewSummaryStatus = true;
      state.error = "";
      state.isError = false;
    },
    reviewTransferMilesFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.error = action.payload;
      state.reviewSummary = {};
      state.reviewSummaryStatus = false;
      state.isError = true;
    },
    resetReviewTransferMiles: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.error = "";
      state.reviewSummary = {};
      state.reviewSummaryStatus = false;
      state.isError = false;
    },
    transferMilesPaymentInitRequest: (state, action) => {
      state.isLoading = true;
    },
    transferMilesPaymentInitSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.transferMilesPaymentInitInfo = action.payload;
      state.error = "";
    },
    transferMilesPaymentInitFailure: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
    resetTransferMilesPaymentInit: (state, action) => {
      state.isLoading = false;
      state.transferMilesPaymentInitInfo = {};
      state.isError = false;
      state.error = "";
    },
    getTransferMilesReceiptDownloadRequest: (state, action) => {
      state.isLoading = true;
      state.downloadData = action.payload;
    },
    getTransferMilesReceiptDownloadSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.receiptData = action.payload;
      state.error = "";
    },
    getTransferMilesReceiptDownloadFailed: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    }
  }
});
export const {
  transferMilesTermsConditionRequest,
  transferMilesTermsConditionSuccess,
  transferMilesTermsConditionFailure,
  reviewTransferMilesRequest,
  reviewTransferMilesSuccess,
  reviewTransferMilesFailure,
  transferMilesPaymentInitRequest,
  transferMilesPaymentInitSuccess,
  transferMilesPaymentInitFailure,
  resetTransferMilesPaymentInit,
  getTransferMilesReceiptDownloadRequest,
  getTransferMilesReceiptDownloadSuccess,
  getTransferMilesReceiptDownloadFailed,
  resetReviewTransferMiles,
  resetTransferMilesTermsCondition
} = transferMilesSlice.actions;
export default transferMilesSlice.reducer;