import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
import { nationalityCountryRequest, nationalityCountrySuccess, nationalityCountryFailure } from "../../slice/checkin/passengerDetails/nationalityCountrySlice";
const data = {
  mode: "no-cors",
  headers: {
    Authorization: localStorage.getItem("checkintoken"),
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*"
  },
  loader: true
};
const fetchNationalityCountryData = async () => {
  const API_URL = config.PASSENGER_DETAILS_COUNTRY;
  const response = await api.get(API_URL, data);
  return response;
};
function* getNationalityCountryData() {
  try {
    const response = yield call(fetchNationalityCountryData);
    yield put(nationalityCountrySuccess(response.data));
  } catch (error) {
    yield put(nationalityCountryFailure(error.message));
  }
}
export function* watchNationalityCountryData() {
  yield takeLatest(nationalityCountryRequest.type, getNationalityCountryData);
}