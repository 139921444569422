import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  cancelCheckinRetrieveInfo: {},
  isLoading: false,
  hasData: false,
  error: "",
  cancelRetrieveCheckinStatus: {
    status: false,
    message: ""
  }
};
const cancelRetrieveCheckinSlice = createSlice({
  name: "cancelRetreiveCheckin",
  initialState,
  reducers: {
    cancelRetrieveCheckinRequest: (state, action) => {
      state.isLoading = true;
    },
    cancelRetrieveCheckinSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.cancelCheckinRetrieveInfo = action.payload;
      state.cancelRetrieveCheckinStatus.status = true;
    },
    cancelRetrieveCheckinFailure: (state, action) => {
      var _action$payload;
      state.isLoading = false;
      state.error = action === null || action === void 0 || (_action$payload = action.payload) === null || _action$payload === void 0 || (_action$payload = _action$payload.response) === null || _action$payload === void 0 ? void 0 : _action$payload.data;
      state.cancelCheckinRetrieveInfo = {};
      state.cancelRetrieveCheckinStatus.status = false;
    },
    resetRetrieveCheckin: state => {
      state.isLoading = true;
      state.error = "";
      state.cancelCheckinRetrieveInfo = {};
      state.cancelRetrieveCheckinStatus.status = false;
    },
    resetRetrieveCheckinStatus: state => {
      state.cancelRetrieveCheckinStatus.status = false;
    }
  }
});
export const {
  cancelRetrieveCheckinRequest,
  cancelRetrieveCheckinSuccess,
  cancelRetrieveCheckinFailure,
  resetRetrieveCheckin,
  resetRetrieveCheckinStatus
} = cancelRetrieveCheckinSlice.actions;
export default cancelRetrieveCheckinSlice.reducer;