import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  SawasdeeMagDetails: [],
  isLoading: false,
  error: "",
  hasData: false
};
const sawasdeeMagSlice = createSlice({
  name: "sawasdeeMagzine",
  initialState,
  reducers: {
    swMagDataRequest: state => {
      state.isLoading = true;
    },
    getSWDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.SawasdeeMagDetails = action.payload;
    },
    getSWDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});
export const {
  swMagDataRequest,
  getSWDataSuccess,
  getSWDataFailure
} = sawasdeeMagSlice.actions;
export default sawasdeeMagSlice.reducer;