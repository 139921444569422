import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  NewsAnnouncementDetails: {},
  isLoading: false,
  error: "",
  hasData: false
};
const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    newsDataRequest: state => {
      state.isLoading = true;
    },
    newsDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.NewsAnnouncementDetails = action.payload;
    },
    newsDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});
export const {
  newsDataRequest,
  newsDataSuccess,
  newsDataFailure
} = newsSlice.actions;
export default newsSlice.reducer;