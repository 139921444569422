import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  myprofileData: {},
  isLoading: false,
  error: "",
  hasData: false,
  crmNationalityData: {}
};
const fetchMainProfileSlice = createSlice({
  name: "MyProfileDeails",
  initialState,
  reducers: {
    fetchMyProfileRequest: state => {
      state.isLoading = true;
    },
    getMyProfileSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.myprofileData = action.payload;
    },
    getMyProfileFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.myprofileData = {};
    },
    fetchCRMNatinalityRequest: state => {
      state.isLoading = true;
    },
    getCRMNatinalitySuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.crmNationalityData = action.payload;
    },
    getCRMNatinalityFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.crmNationalityData = {};
    }
  }
});
export const {
  fetchMyProfileRequest,
  getMyProfileSuccess,
  getMyProfileFailure,
  fetchCRMNatinalityRequest,
  getCRMNatinalitySuccess,
  getCRMNatinalityFailure
} = fetchMainProfileSlice.actions;
export default fetchMainProfileSlice.reducer;