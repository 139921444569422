import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  SecondaryCarouselDetails: [],
  isLoading: false,
  error: "",
  hasData: false
};
const secondaryCarouselSlice = createSlice({
  name: "secondaryCarousel",
  initialState,
  reducers: {
    getHomeSecondaryCarouselDataRequest: state => {
      console.log('state', state);
      state.isLoading = true;
    },
    getHomeSecondaryCarouselDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.SecondaryCarouselDetails = action.payload;
    },
    getHomeSecondaryCarouselDataFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});
export const {
  getHomeSecondaryCarouselDataRequest,
  getHomeSecondaryCarouselDataSuccess,
  getHomeSecondaryCarouselDataFail
} = secondaryCarouselSlice.actions;
export default secondaryCarouselSlice.reducer;