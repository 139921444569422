import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { preferenceFromDataRequest, getPreferenceFromDataSuccess, getPreferenceFromDataFailure } from "../slice/newsletterPreferenceSlice";
const API_URL = "./json/newsletterPreferenceList.json";
function* getPreferenceFromData() {
  try {
    const response = yield call(axios.get, API_URL, {
      baseURL: "/"
    });
    yield put(getPreferenceFromDataSuccess(response.data));
  } catch (error) {
    yield put(getPreferenceFromDataFailure(error.message));
  }
}
export function* watchPrefernceFromData() {
  yield takeLatest(preferenceFromDataRequest.type, getPreferenceFromData);
}