import { createSlice } from "@reduxjs/toolkit";
// import { FlightListspDataState } from "../models/models";

const initialState = {
  airAwardPaymentInitInfo: {},
  isLoading: false,
  hasData: false,
  error: ""
};
const airAwardPaymentInitSlice = createSlice({
  name: "airAwardPaymentInit",
  initialState,
  reducers: {
    airAwardPaymentInitRequest: state => {
      state.isLoading = true;
    },
    airAwardPaymentInitSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.airAwardPaymentInitInfo = action.payload;
    },
    airAwardPaymentInitFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    airAwardPaymentInitReset: state => {
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.airAwardPaymentInitInfo = {};
    }
  }
});
export const {
  airAwardPaymentInitRequest,
  airAwardPaymentInitSuccess,
  airAwardPaymentInitFailure,
  airAwardPaymentInitReset
} = airAwardPaymentInitSlice.actions;
export default airAwardPaymentInitSlice.reducer;