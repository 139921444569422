import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { getSubscribeDataPending, getSubscribeSuccess, getSubscribeFailure } from "../slice/subscribeSlice";
const SUBSCRIBE_RESET_URL = "common/subscription/subscribe";
const fetchSubscribeResetData = async subscribePayload => {
  let subscribePayloadData = {
    email: subscribePayload.email,
    dateOfBirth: subscribePayload.dateOfBirth,
    preferenceLocation: subscribePayload.preferenceLocation
  };
  let headerData = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  const response = await api.post(SUBSCRIBE_RESET_URL, subscribePayloadData, headerData);
  return response;
};
function* getSubscribeData(action) {
  try {
    const response = yield call(fetchSubscribeResetData, action.payload);
    yield put(getSubscribeSuccess(response.data));
  } catch (error) {
    yield put(getSubscribeFailure(error));
  }
}
export function* watchSubscribeData() {
  yield takeLatest(getSubscribeDataPending.type, getSubscribeData);
}