import { call, put, takeEvery } from "redux-saga/effects";
import { airAwardAirportRequest, airAwardAirportSuccess, airAwardAirportFailure } from "../slice/airAwardAirportSlice";
import api from "../../../config/api";
import { config } from "../../../config/global";
const API_URL = config === null || config === void 0 ? void 0 : config.AIR_AWARD_AIRPORT_API;
const fetchAirAwardAirportDetail = async payload => {
  let headerData = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken"
    },
    loader: true
  };
  const response = await api.get(API_URL, headerData);
  return response;
};
function* fetchAirAwardAirport(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchAirAwardAirportDetail, payload);
    yield put(airAwardAirportSuccess(response));
  } catch (error) {
    yield put(airAwardAirportFailure(error === null || error === void 0 ? void 0 : error.response));
  }
}
export function* watchAirAwardAirport() {
  yield takeEvery(airAwardAirportRequest.type, fetchAirAwardAirport);
}