import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  airLineListData: [],
  isLoading: false,
  hasData: false,
  error: ""
};
const airLineListSlice = createSlice({
  name: "airLineList",
  initialState,
  reducers: {
    airLineListRequest: state => {
      state.isLoading = true;
    },
    airLineListSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.airLineListData = action.payload.data[1];
    },
    airLineListFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});
export const {
  airLineListRequest,
  airLineListSuccess,
  airLineListFailure
} = airLineListSlice.actions;
export default airLineListSlice.reducer;