import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  crmLoginDetails: {},
  isLoading: false,
  error: "",
  hasData: false,
  codeStatus: "pending",
  crmSocialLoginValidationDetails: {},
  crmSocialLoginValidationErrors: "",
  crmSocialLoginValidationLoading: false,
  crmSocialLoginType: ""
};
const crmLoginSlice = createSlice({
  name: "crmLogin",
  initialState,
  reducers: {
    getCrmLoginDataPending: (state, action) => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    getCrmLoginDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.crmLoginDetails = action.payload;
      state.codeStatus = "success";
      sessionStorage.setItem("refNo", action.payload.refNo);
      localStorage.setItem("isROPLoggedIn", JSON.stringify(false));
    },
    getCrmLoginDataFailure: (state, action) => {
      state.isLoading = false;
      state.codeStatus = "failure";
      state.error = action.payload;
    },
    resetCrmLoginData: state => {
      state.codeStatus = "pending";
      state.error = "";
      state.crmLoginDetails = {};
      state.hasData = false;
    },
    getCrmSocialMediaValidationPending: (state, action) => {
      state.crmSocialLoginValidationLoading = true;
      state.crmSocialLoginType = action.payload.socialMediaType;
    },
    getCrmSocialMediaValidationSuccess: (state, action) => {
      state.crmSocialLoginValidationLoading = false;
      let fullName = "";
      if (!action.payload.newUser) {
        var _action$payload$profi, _action$payload$profi2, _action$payload, _action$payload$profi3, _action$payload2, _action$payload3, _action$payload4;
        fullName = ((_action$payload$profi = action.payload.profileDetails) === null || _action$payload$profi === void 0 ? void 0 : _action$payload$profi.salutation) + " " + ((_action$payload$profi2 = action.payload.profileDetails) === null || _action$payload$profi2 === void 0 ? void 0 : _action$payload$profi2.firstName) + " " + ((_action$payload = action.payload) === null || _action$payload === void 0 || (_action$payload = _action$payload.profileDetails) === null || _action$payload === void 0 ? void 0 : _action$payload.lastName);
        sessionStorage.setItem("FullName", fullName);
        sessionStorage.setItem("FirstName", (_action$payload$profi3 = action.payload.profileDetails) === null || _action$payload$profi3 === void 0 ? void 0 : _action$payload$profi3.firstName);
        sessionStorage.setItem("LastName", (_action$payload2 = action.payload) === null || _action$payload2 === void 0 || (_action$payload2 = _action$payload2.profileDetails) === null || _action$payload2 === void 0 ? void 0 : _action$payload2.lastName);
        sessionStorage.setItem("Salutation", (_action$payload3 = action.payload) === null || _action$payload3 === void 0 || (_action$payload3 = _action$payload3.profileDetails) === null || _action$payload3 === void 0 ? void 0 : _action$payload3.salutation);
        sessionStorage.setItem("profileName", fullName);
        sessionStorage.setItem("customerID", (_action$payload4 = action.payload) === null || _action$payload4 === void 0 || (_action$payload4 = _action$payload4.profileDetails) === null || _action$payload4 === void 0 ? void 0 : _action$payload4.customerID);
        localStorage.setItem("isROPLoggedIn", "false");
      }
      state.crmSocialLoginValidationDetails = action.payload;
      state.crmSocialLoginValidationErrors = "";
    },
    getCrmSocialMediaValidationFailure: (state, action) => {
      state.crmSocialLoginValidationLoading = false;
      state.crmSocialLoginValidationErrors = action.payload;
    },
    resetCrmSocialValidationData: state => {
      state.crmSocialLoginValidationDetails = {};
    }
  }
});
export const {
  getCrmLoginDataPending,
  getCrmLoginDataSuccess,
  getCrmLoginDataFailure,
  resetCrmLoginData,
  getCrmSocialMediaValidationPending,
  getCrmSocialMediaValidationSuccess,
  getCrmSocialMediaValidationFailure,
  resetCrmSocialValidationData
} = crmLoginSlice.actions;
export default crmLoginSlice.reducer;