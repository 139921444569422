import React from "react";
export const useOutsideClick = callback => {
  const ref = React.useRef();
  React.useEffect(() => {
    const handleClick = event => {
      var _ref$current;
      if (ref !== null && ref !== void 0 && ref.current && !(ref !== null && ref !== void 0 && (_ref$current = ref.current) !== null && _ref$current !== void 0 && _ref$current.contains(event.target))) {
        callback();
      }
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref]);
  return ref;
};