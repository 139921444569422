import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { getMemberTierCalclulatorPending, getMemberTierCalclulatorSuccess, getMemberTierCalclulatorFailure, getMemberAwardPending, getMemberAwardSuccess, getMemberAwardFailure } from "../slice/fetchMemberStatusCalculatorSlice";
import { config } from "../../../config/global";
const MEMBER_TIER_CALCULATOR_API_URL = config.MEMBER_TIER_CALCULATOR;
const fetchMemberTierCalclulatorData = async MemberTierCalclulatorPayload => {
  let headerData = {
    headers: {
      memberId: sessionStorage.getItem("memberId"),
      Authorization: localStorage.getItem("accesstoken"),
      "Content-Type": "application/json"
    },
    isGenericErr: true,
    loader: true
  };
  const response = await api.post(MEMBER_TIER_CALCULATOR_API_URL, MemberTierCalclulatorPayload, headerData);
  return response;
};
function* postMemberTierCalclulatorData(action) {
  try {
    const response = yield call(fetchMemberTierCalclulatorData, action.payload);
    yield put(getMemberTierCalclulatorSuccess(response.data));
    console.log("response", response.data);
  } catch (error) {
    yield put(getMemberTierCalclulatorFailure(error));
  }
}
const MEMBER_AWARD_API_URL = config.MEMBER_AWARD;
const fetchMemberAwardData = async () => {
  let headerData = {
    headers: {
      Authorization: localStorage.getItem("accesstoken"),
      "Content-Type": "application/json"
    },
    isGenericErr: true,
    loader: true
  };
  const response = await api.get(MEMBER_AWARD_API_URL, headerData);
  return response;
};
function* postMemberAwardData() {
  try {
    const response = yield call(fetchMemberAwardData);
    yield put(getMemberAwardSuccess(response.data));
    console.log("response", response.data);
  } catch (error) {
    yield put(getMemberAwardFailure(error));
  }
}
export function* watchMemberTierCalclulatorData() {
  yield takeLatest(getMemberTierCalclulatorPending.type, postMemberTierCalclulatorData);
}
export function* watchMemberAwardData() {
  yield takeLatest(getMemberAwardPending.type, postMemberAwardData);
}