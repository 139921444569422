import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  addPassengersData: {},
  isLoading: false,
  hasData: false,
  addPaxSuccess: false,
  error: ""
};
const fetchAddPassengerSlice = createSlice({
  name: "addPassenger",
  initialState,
  reducers: {
    addPassengerRequest: (state, action) => {
      state.isLoading = true;
      state.addPaxSuccess = false;
      state.error = "";
    },
    addPassengerSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.addPaxSuccess = true;
      state.addPaxDetails = action.payload;
    },
    addPassengerFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.response.data;
      state.addPaxSuccess = false;
      state.addPaxDetails = {};
    },
    resetAddPassengerDetails: state => {
      state.isLoading = false;
      state.addPaxDetails = {};
      state.addPaxSuccess = false;
      state.hasData = false;
      state.error = "";
    },
    resetPaxStatus: state => {
      state.addPaxSuccess = false;
    }
  }
});
export const {
  addPassengerRequest,
  addPassengerSuccess,
  addPassengerFailure,
  resetAddPassengerDetails,
  resetPaxStatus
} = fetchAddPassengerSlice.actions;
export default fetchAddPassengerSlice.reducer;