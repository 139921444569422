import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  flightRouteDetail: null,
  flightRouteInputs: null,
  flightRoute: null,
  isLoading: false,
  error: false
};
const FlightStatusRouteSlice = createSlice({
  name: "flightStatus",
  initialState,
  reducers: {
    flightStatusByRouteRequest: (state, action) => {
      state.isLoading = true;
      state.error = false;
    },
    flightStatusByRouteSuccess: (state, action) => {
      state.flightRouteDetail = action.payload;
      state.isLoading = false;
    },
    flightStatusByRouteFailure: (state, action) => {
      state.error = true;
      state.isLoading = false;
      state.flightRouteDetail = null;
    },
    flightStatusRouteInputSave: (state, action) => {
      state.flightRouteInputs = action.payload;
    },
    flightStatusRouteClear: state => {
      state.flightRouteInputs = null;
      state.flightRouteDetail = null;
      state.flightRoute = null;
      state.isLoading = false;
      state.error = false;
    }
  }
});
export const {
  flightStatusByRouteRequest,
  flightStatusByRouteSuccess,
  flightStatusByRouteFailure,
  flightStatusRouteInputSave,
  flightStatusRouteClear
} = FlightStatusRouteSlice.actions;
export default FlightStatusRouteSlice.reducer;