import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  selectPassengerInfo: {},
  isLoading: false,
  hasData: false,
  error: "",
  selectPassengerStatus: {
    status: false,
    message: ""
  },
  selectedPassengerData: {}
};
const selectPassengerSlice = createSlice({
  name: "selectPassenger",
  initialState,
  reducers: {
    selectPassengerRequest: (state, action) => {
      state.isLoading = true;
    },
    selectPassengerSuccess: (state, action) => {
      var _action$payload;
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.selectPassengerInfo = action.payload;
      state.selectPassengerStatus.status = true;
      state.selectPassengerStatus.message = action === null || action === void 0 || (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.message;
    },
    selectPassengerFailure: (state, action) => {
      var _action$payload2;
      console.log("select passenger line 28");
      state.isLoading = false;
      state.error = action === null || action === void 0 || (_action$payload2 = action.payload) === null || _action$payload2 === void 0 || (_action$payload2 = _action$payload2.response) === null || _action$payload2 === void 0 ? void 0 : _action$payload2.data;
      state.selectPassengerInfo = {};
      state.selectPassengerStatus.status = false;
      state.selectPassengerStatus.message = "";
    },
    resetRemovePassengers: state => {
      state.selectPassengerInfo = {};
      state.selectedPassengerData = {};
      state.isLoading = false;
      state.hasData = false;
      state.error = "";
      state.selectPassengerStatus.status = false;
      state.selectPassengerStatus.message = "";
    },
    setSelectPassengerData: (state, action) => {
      state.selectedPassengerData = action.payload;
    }
  }
});
export const {
  selectPassengerRequest,
  selectPassengerSuccess,
  selectPassengerFailure,
  resetRemovePassengers,
  setSelectPassengerData
} = selectPassengerSlice.actions;
export default selectPassengerSlice.reducer;