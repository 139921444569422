import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  AdCarouselDetails: [],
  AdCardDetails: [],
  isLoadingCard: false,
  errorCard: "",
  isLoadingCarousel: false,
  errorCarousel: ""
};
const adCarouselSlice = createSlice({
  name: "adCarousel",
  initialState,
  reducers: {
    adCarouselDataRequest: state => {
      state.isLoadingCarousel = true;
    },
    getAdCarouselDataSuccess: (state, action) => {
      state.isLoadingCarousel = false;
      state.errorCarousel = "";
      state.AdCarouselDetails = action.payload;
    },
    getAdCarouselDataFailure: (state, action) => {
      state.errorCarousel = action.payload;
      state.isLoadingCarousel = false;
    },
    adCardDataRequest: state => {
      state.isLoadingCard = true;
    },
    getAdCardDataSuccess: (state, action) => {
      state.isLoadingCard = false;
      state.errorCard = "";
      state.AdCardDetails = action.payload;
    },
    getAdCardDataFailure: (state, action) => {
      state.errorCard = action.payload;
      state.isLoadingCard = false;
    }
  }
});
export const {
  adCarouselDataRequest,
  getAdCarouselDataSuccess,
  getAdCarouselDataFailure,
  adCardDataRequest,
  getAdCardDataSuccess,
  getAdCardDataFailure
} = adCarouselSlice.actions;
export default adCarouselSlice.reducer;