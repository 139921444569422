import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  profileContactInfoDet: {},
  crmProfileContactInfo: {},
  isLoading: false,
  error: "",
  hasData: false,
  codeStatus: "pending",
  profileContactInfoStatus: {
    status: false,
    message: ""
  },
  crmprofileContactInfoStatus: {
    status: false,
    message: ""
  },
  isUpdateSuccess: false,
  isEditable: false,
  isProfileUpdateSuccess: false,
  isProfileEditable: false
};
const profileContactInfoSlice = createSlice({
  name: "profileContactInfo",
  initialState,
  reducers: {
    getProfileContactInfoPending: (state, action) => {
      state.isLoading = true;
      state.codeStatus = "pending";
      state.isUpdateSuccess = false;
    },
    getProfileContactInfoFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.profileContactInfoDet = action.payload;
      state.isUpdateSuccess = false;
      // console.log("enter failure",action.payload);
      // if(state.profileContactInfoDet.code =="I120")
      //   {
      //     console.log("enter failure");
      //     state.profileContactInfoStatus.message ="Successfully Updated";
      //     state.profileContactInfoStatus.status = true;
      //     // sessionStorage.setItem('memberId',state.profileContactInfoDet.memberId);
      //   }

      // state.codeStatus = "success";
    },
    getProfileContactInfoSuccess: (state, action) => {
      state.isLoading = false;
      state.codeStatus = "failure";
      if (action.payload.code === "I120") {
        state.profileContactInfoStatus.message = "Profile updated successfully";
        state.profileContactInfoStatus.status = true;
        state.isUpdateSuccess = true;
      } else {
        state.profileContactInfoStatus.message = "Something went wrong";
        state.profileContactInfoStatus.status = false;
        state.isUpdateSuccess = false;
      }
    },
    updateSignInState: (state, action) => {
      const {
        updatedState
      } = action.payload;
      state.isSignin = updatedState;
    },
    setIsEditable: (state, action) => {
      state.isEditable = action.payload;
    },
    setProfileIsEditable: (state, action) => {
      state.isProfileEditable = action.payload;
    },
    getCRMProfileContactInfoPending: (state, action) => {
      state.isLoading = true;
      state.codeStatus = "pending";
      state.isUpdateSuccess = false;
    },
    getCRMProfileContactInfoSuccess: (state, action) => {
      state.isLoading = false;
      state.codeStatus = "failure";
      if (action.payload.success === true) {
        state.crmprofileContactInfoStatus.message = "Profile updated successfully";
        state.crmprofileContactInfoStatus.status = true;
        state.isUpdateSuccess = true;
      } else {
        state.crmprofileContactInfoStatus.message = "Something went wrong";
        state.crmprofileContactInfoStatus.status = false;
        state.isUpdateSuccess = false;
      }
    },
    getCRMProfileContactInfoFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.crmProfileContactInfo = action.payload;
      state.isUpdateSuccess = false;
    }
  }
});
export const {
  getProfileContactInfoPending,
  getProfileContactInfoFailure,
  getProfileContactInfoSuccess,
  updateSignInState,
  setIsEditable,
  setProfileIsEditable,
  getCRMProfileContactInfoPending,
  getCRMProfileContactInfoSuccess,
  getCRMProfileContactInfoFailure
} = profileContactInfoSlice.actions;
export default profileContactInfoSlice.reducer;