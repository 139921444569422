import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  ResetDetails: {},
  isLoading: false,
  error: {},
  hasData: false
};
const resetSlice = createSlice({
  name: "reset",
  initialState,
  reducers: {
    resetDataRequest: (state, action) => {
      state.isLoading = true;
      state.error = "";
      state.hasData = false;
      state.ResetDetails = {};
    },
    resetDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.ResetDetails = action.payload;
    },
    resetDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.ResetDetails = {};
    }
  }
});
export const {
  resetDataRequest,
  resetDataSuccess,
  resetDataFailure
} = resetSlice.actions;
export default resetSlice.reducer;