import { call, put, takeEvery } from "redux-saga/effects";
import { getHomeSecondaryCarouselDataFail, getHomeSecondaryCarouselDataRequest, getHomeSecondaryCarouselDataSuccess } from "../slice/secondaryCarouselSlice";
// import api from '../../../config/api';
import axios from "axios";
const API_URL = "./json/secondaryCarouselData.json";
function* fetchSecondaryCarouselSaga() {
  try {
    const response = yield call(axios.get, API_URL, {
      baseURL: "/"
    });
    yield put(getHomeSecondaryCarouselDataSuccess(response.data));
  } catch (error) {
    yield put(getHomeSecondaryCarouselDataFail(error.message));
  }
}
export function* watchFetchSecondaryCarouselData() {
  yield takeEvery(getHomeSecondaryCarouselDataRequest.type, fetchSecondaryCarouselSaga);
}