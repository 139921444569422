import { call, put, takeLatest } from "redux-saga/effects";
// import api from "../config/api";
import axios from "axios";
import { getThaiCardDataFailure, getThaiCardDataSuccess, thaiCardDataRequest } from "../slice/thaiCardApplicationSlice";
const THAICARDURL = "./json/thaiCardApplication.json";
function* getThaiCardData() {
  try {
    const response = yield call(axios.get, THAICARDURL);
    yield put(getThaiCardDataSuccess(response.data));
  } catch (error) {
    yield put(getThaiCardDataFailure(error.message));
  }
}
export function* watchThaiCardData() {
  yield takeLatest(thaiCardDataRequest.type, getThaiCardData);
}