import { call, put, takeEvery } from 'redux-saga/effects';
import { getROHDataRequest, getROHDataFail, getROHDataSuccess } from '../../slice/rohSlice';
// import api from '../../config/api';
import axios from "axios";
const data = {
  mode: "no-cors",
  headers: {
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*"
  },
  body: {}
};
const currentCountry = localStorage.getItem('country') || 'th';
const currentLanguage = localStorage.getItem('i18nextLng') || 'en';
const fetchROHData = async rohDataPayload => {
  const path = "/assets/".concat(currentCountry, "/").concat(currentLanguage, "/roh.json");
  // const ROH_URL = config.TEAMSITE_ROH_LIST;
  const response = await axios.get(path, data);
  return response;
};
function* fetchROHSaga(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchROHData, payload);
    yield put(getROHDataSuccess(response.data));
  } catch (error) {
    yield put(getROHDataFail(error.message));
  }
}
export function* watchFetchROHData() {
  yield takeEvery(getROHDataRequest.type, fetchROHSaga);
}