import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  data: {},
  manageMiles: [],
  milesAwardList: {},
  milesAwardDetail: {},
  isLoading: false,
  isError: false,
  error: null,
  manageMilesLoading: false,
  manageMilesError: null,
  milesAwardListLoading: false,
  milesAwardListError: null,
  milesAwardDetailLoading: false,
  milesAwardDetailError: null,
  milesReportData: {},
  milesReportError: null,
  milesReportLoading: false
};
const MileageStatementSlice = createSlice({
  name: "flightStatus",
  initialState,
  reducers: {
    getMileageStatementPending: (state, action) => {
      state.isLoading = true;
      state.isError = false;
    },
    getMileageStatementSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
    },
    getMileageStatementFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isError = false;
    },
    getManageMilesDataPending: state => {
      state.manageMilesLoading = true;
    },
    getManageMilesSuccess: (state, action) => {
      state.manageMilesLoading = false;
      state.error = "";
      state.manageMiles = action.payload.response;
    },
    getManageMilesFailure: (state, action) => {
      state.manageMilesLoading = false;
      state.manageMilesError = action.payload;
    },
    getMilesAwardListDataPending: (state, action) => {
      state.milesAwardListLoading = true;
    },
    getMilesAwardListSuccess: (state, action) => {
      state.milesAwardListLoading = false;
      state.milesAwardListError = "";
      state.milesAwardList = action.payload;
    },
    getMilesAwardListFailure: (state, action) => {
      state.milesAwardListLoading = false;
      state.milesAwardListError = action.payload;
    },
    getMilesAwardDetailDataPending: (state, action) => {
      state.milesAwardDetailLoading = true;
    },
    getMilesAwardDetailSuccess: (state, action) => {
      state.milesAwardDetailLoading = false;
      state.milesAwardDetailError = "";
      state.milesAwardDetail = action.payload;
    },
    getMilesAwardDetailFailure: (state, action) => {
      state.milesAwardDetailLoading = false;
      state.milesAwardDetailError = action.payload;
    },
    getMilesStatementReportPending: (state, action) => {
      state.milesReportLoading = true;
    },
    getMilesStatementReportSuccess: (state, action) => {
      state.milesReportLoading = false;
      state.milesReportError = "";
      state.milesReportData = action.payload;
    },
    getMilesStatementReportFailure: (state, action) => {
      state.milesReportLoading = false;
      state.milesReportError = action.payload;
    },
    resetMileageInfoData: state => {
      state.data = {};
      state.milesAwardList = {};
      state.milesAwardDetail = {};
      state.milesReportData = {};
    },
    getMileageStatementDatePending: (state, action) => {
      state.isLoading = true;
      state.isError = false;
    },
    getMileageStatementDateSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
    },
    getMileageStatementDateFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isError = false;
    }
  }
});
export const {
  getMileageStatementPending,
  getMileageStatementSuccess,
  getMileageStatementFailure,
  getManageMilesDataPending,
  getManageMilesSuccess,
  getManageMilesFailure,
  getMilesAwardListDataPending,
  getMilesAwardListSuccess,
  getMilesAwardListFailure,
  getMilesAwardDetailDataPending,
  getMilesAwardDetailSuccess,
  getMilesAwardDetailFailure,
  getMilesStatementReportPending,
  getMilesStatementReportSuccess,
  getMilesStatementReportFailure,
  resetMileageInfoData,
  getMileageStatementDatePending,
  getMileageStatementDateSuccess,
  getMileageStatementDateFailure
} = MileageStatementSlice.actions;
export default MileageStatementSlice.reducer;