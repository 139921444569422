import axios from "axios";
//process.env variable to be added
//apiconfig/ apiconstants to be added

//ToDo: Need to change when CMS comes. Now only handling english and thai
const countryLanguageCode = window.location.pathname.split("/")[1] === "th-th" ? "th-th" : "en-th";
const cmsApi = axios.create({
  //ToDo: appending the countrycode-languageCode for now as final ts url is not yet finalized
  baseURL: process.env.REACT_APP_TG_TEAMSITE_URL + countryLanguageCode
});

// Request interceptor
cmsApi.interceptors.request.use(config => {
  // Modify the request config here (e.g., add headers, authentication tokens)

  return config;
}, error => {
  // Handle request errors here

  return Promise.reject(error);
});

// Response interceptor
cmsApi.interceptors.response.use(response => {
  // Modify the response data here (e.g., parse, transform)

  return response;
}, error => {
  // Handle response errors here

  return Promise.reject(error);
});
export default cmsApi;