import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../../config/api";
import { config } from "../../../config/global";
import { airAwardConfirmationFail, airAwardConfirmationRequest, airAwardConfirmationSuccess } from "../slice/airAwardConfirmationSlice";
const API_URL = config === null || config === void 0 ? void 0 : config.AIR_AWARD_CONFIRMATION;
const fetchAirAwardConfirmation = async payload => {
  let headerData = {
    headers: {
      Authorization: localStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
      source: "website"
    },
    loader: true
  };
  const response = await api.post(API_URL, payload, headerData);
  return response;
};
function* airAwardConfirmationSaga(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchAirAwardConfirmation, payload);
    yield put(airAwardConfirmationSuccess({
      ...response
    }));
  } catch (error) {
    yield put(airAwardConfirmationFail({
      ...(error === null || error === void 0 ? void 0 : error.response)
    }));
  }
}
export function* watchAirAwardConfirmationData() {
  yield takeEvery(airAwardConfirmationRequest.type, airAwardConfirmationSaga);
}