import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  unsubscribeDetails: {},
  isLoading: false,
  error: {},
  hasData: false,
  unsubscribestatus: {
    status: false,
    message: ""
  }
};
const unSubscribeResetSlice = createSlice({
  name: "unsubscribeReset",
  initialState,
  reducers: {
    getUnsubscribeDataPending: (state, action) => {
      state.isLoading = true;
      state.unsubscribestatus.status = false;
      state.unsubscribestatus.message = "";
      state.error = {};
    },
    getUnSubscribeSuccess: (state, action) => {
      console.log("UnSubscribe playload", action.payload);
      state.isLoading = false;
      state.hasData = true;
      state.unsubscribeDetails = action.payload;
      state.unsubscribestatus.status = true;
    },
    getUnSubscribeFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.response.data;
      state.unsubscribestatus.status = false;
      if (state.error.code === "400") {
        state.unsubscribestatus.message = "Invalid Email";
        state.unsubscribestatus.status = false;
      }
    },
    resetUnSubscribeData: state => {
      state.error = {};
      state.isLoading = false;
      state.hasData = false;
      state.unsubscribeDetails = {};
      state.unsubscribestatus = {
        status: false,
        message: ""
      };
    }
  }
});
export const {
  getUnsubscribeDataPending,
  getUnSubscribeFailure,
  getUnSubscribeSuccess,
  resetUnSubscribeData
} = unSubscribeResetSlice.actions;
export default unSubscribeResetSlice.reducer;