import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  modalDetails: [],
  isLoadingModalDetails: false,
  errorModalDetails: "",
  nationDetails: [],
  isLoadingNationDetails: false,
  errorNationDetails: "",
  loginModalVisible: false
};
const signUpSlice = createSlice({
  name: "signUp",
  initialState,
  reducers: {
    termsPrivacyModalDataRequest: state => {
      state.isLoadingModalDetails = true;
    },
    getTermsPrivacyModalSuccess: (state, action) => {
      state.isLoadingModalDetails = false;
      state.errorModalDetails = "";
      state.modalDetails = action.payload;
    },
    getTermsPrivacyModalFailure: (state, action) => {
      state.errorModalDetails = action.payload;
      state.isLoadingModalDetails = false;
    },
    nationDataRequest: state => {
      state.isLoadingNationDetails = true;
    },
    getNationDataSuccess: (state, action) => {
      state.isLoadingNationDetails = false;
      state.errorNationDetails = "";
      state.nationDetails = action.payload;
    },
    getNationDataFailure: (state, action) => {
      state.errorNationDetails = action.payload;
      state.isLoadingNationDetails = false;
    },
    updateLoginModalState: (state, action) => {
      const {
        value
      } = action.payload;
      state.loginModalVisible = value;
    }
  }
});
export const {
  termsPrivacyModalDataRequest,
  getTermsPrivacyModalSuccess,
  getTermsPrivacyModalFailure,
  nationDataRequest,
  getNationDataSuccess,
  getNationDataFailure,
  updateLoginModalState
} = signUpSlice.actions;
export default signUpSlice.reducer;