import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  profilePersonalInfoDet: {},
  profilePersonalInfoStatus: {
    status: false,
    message: ""
  },
  isLoading: false,
  error: "",
  hasData: false,
  codeStatus: "",
  isUpdateSuccess: false,
  isEditable: false,
  isProfileUpdateSuccess: false,
  isProfileEditable: false,
  myDeleteAccTerms: {},
  deleteAccTermsError: ""
};
const profilePersonalInfoSlice = createSlice({
  name: "profilePersonalInfo",
  initialState,
  reducers: {
    getProfilePersonalInfoPending: (state, action) => {
      state.isLoading = true;
      state.codeStatus = "pending";
      state.isUpdateSuccess = false;
    },
    getProfilePersonalInfoFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.profilePersonalInfoDet = action.payload;
      state.isUpdateSuccess = false;
    },
    getProfilePersonalInfoSuccess: (state, action) => {
      state.isLoading = false;
      state.codeStatus = "failure";
      if (action.payload.code === "I120") {
        state.profilePersonalInfoStatus.message = "Profile updated successfully";
        state.profilePersonalInfoStatus.status = true;
        state.isUpdateSuccess = true;
      } else {
        state.profilePersonalInfoStatus.message = "Something went wrong";
        state.profilePersonalInfoStatus.status = false;
        state.isUpdateSuccess = false;
      }
    },
    getCrmProfilePersonalInfoPending: (state, action) => {
      state.isLoading = true;
      state.codeStatus = "pending";
      state.isUpdateSuccess = false;
    },
    getCrmProfilePersonalInfoFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.profilePersonalInfoDet = action.payload;
      state.isUpdateSuccess = false;
    },
    getCrmProfilePersonalInfoSuccess: (state, action) => {
      state.isLoading = false;
      state.codeStatus = "failure";
      if (action.payload.success === true) {
        state.profilePersonalInfoStatus.message = "Profile updated successfully";
        state.profilePersonalInfoStatus.status = true;
        state.isUpdateSuccess = true;
      } else {
        state.profilePersonalInfoStatus.message = "Something went wrong";
        state.profilePersonalInfoStatus.status = false;
        state.isUpdateSuccess = false;
      }
    },
    updateSignInState: (state, action) => {
      const {
        updatedState
      } = action.payload;
      state.isSignin = updatedState;
    },
    setIsPersonalInfoEditable: (state, action) => {
      state.isEditable = action.payload;
    },
    setProfileIsEditable: (state, action) => {
      state.isProfileEditable = action.payload;
    },
    getMyDeleteAccTermsSuccess: (state, action) => {
      state.isLoading = false;
      state.deleteAccTermsError = "";
      state.hasData = true;
      state.myDeleteAccTerms = action.payload;
    },
    getMyDeleteAccTermsFailure: (state, action) => {
      state.deleteAccTermsError = action.payload;
      state.isLoading = false;
    }
  }
});
export const {
  getProfilePersonalInfoPending,
  getProfilePersonalInfoSuccess,
  getProfilePersonalInfoFailure,
  getCrmProfilePersonalInfoPending,
  getCrmProfilePersonalInfoSuccess,
  getCrmProfilePersonalInfoFailure,
  updateSignInState,
  setIsPersonalInfoEditable,
  setProfileIsEditable,
  getMyDeleteAccTermsSuccess,
  getMyDeleteAccTermsFailure
} = profilePersonalInfoSlice.actions;
export default profilePersonalInfoSlice.reducer;