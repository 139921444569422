import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  selectedRoutes: {},
  isLoading: false,
  hasData: false,
  error: ""
};
const flightListsSlice = createSlice({
  name: "selectedRoutes",
  initialState,
  reducers: {
    flightListDataRequest: state => {
      state.isLoading = true;
    },
    getFlightListDataSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.selectedRoutes = action.payload;
    },
    getFlightListDataFailure: (state, action) => {
      state.isLoading = true;
      state.error = action.payload;
    }
  }
});
export const {
  flightListDataRequest,
  getFlightListDataSuccess,
  getFlightListDataFailure
} = flightListsSlice.actions;
export default flightListsSlice.reducer;