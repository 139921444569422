import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoading: false,
  isloadingTier: false,
  isloadingAward: false,
  memberStatusTermsData: {},
  memberStatusTermsError: "",
  memberTierData: {},
  getDataSuccess: false,
  memberTierError: {},
  memberAwardData: {},
  memberAwardError: {}
};
const fetchMemberStatusCalculatorSlice = createSlice({
  name: "memberStatusCalculator",
  initialState,
  reducers: {
    getMemberStatusTermsSuccess: (state, action) => {
      state.isLoading = false;
      state.memberStatusTermsError = "";
      state.memberStatusTermsData = action.payload;
    },
    getMemberStatusTermsFailure: (state, action) => {
      state.memberStatusTermsError = action.payload;
      state.isLoading = false;
    },
    getMemberTierCalclulatorPending: (state, action) => {
      state.isloadingTier = true;
      state.getDataSuccess = false;
    },
    getMemberTierCalclulatorFailure: (state, action) => {
      state.isloadingTier = false;
      state.getDataSuccess = false;
      state.memberTierError = action.payload;
    },
    getMemberTierCalclulatorSuccess: (state, action) => {
      state.isloadingTier = false;
      state.memberTierData = action.payload;
      state.getDataSuccess = true;
    },
    getMemberAwardPending: state => {
      state.isloadingAward = true;
      state.getDataSuccess = false;
    },
    getMemberAwardFailure: (state, action) => {
      state.isloadingAward = false;
      state.getDataSuccess = false;
      state.memberAwardError = action.payload;
    },
    getMemberAwardSuccess: (state, action) => {
      state.isloadingAward = false;
      state.memberAwardData = action.payload;
      state.getDataSuccess = true;
    }
  }
});
export const {
  getMemberStatusTermsSuccess,
  getMemberStatusTermsFailure,
  getMemberTierCalclulatorPending,
  getMemberTierCalclulatorSuccess,
  getMemberTierCalclulatorFailure,
  getMemberAwardPending,
  getMemberAwardSuccess,
  getMemberAwardFailure
} = fetchMemberStatusCalculatorSlice.actions;
export default fetchMemberStatusCalculatorSlice.reducer;