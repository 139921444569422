import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  data: {},
  isLoading: false,
  error: "",
  hasData: false,
  pnr: "",
  email: "",
  phoneNum: "",
  itineraryInfos: [],
  fareInfo: [],
  taxInfo: [],
  totalFare: "",
  totalMiles: "",
  currency: "",
  session: {
    sessionId: "",
    sequenceNumber: "",
    securityToken: "",
    transactionStatusCode: ""
  }
};
const retrivePNRSlice = createSlice({
  name: "retrivePNRData",
  initialState,
  reducers: {
    retrivePNRRequest: (state, action) => {
      state.isLoading = true;
    },
    retrivePNRSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.data = action.payload;
    },
    retrivePNRFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    retrivePNRReset: state => {
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.data = {};
    }
  }
});
export const {
  retrivePNRRequest,
  retrivePNRSuccess,
  retrivePNRFail,
  retrivePNRReset
} = retrivePNRSlice.actions;
export default retrivePNRSlice.reducer;