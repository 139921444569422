import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  footerData: {},
  isLoading: true,
  error: "",
  hasData: false
};
const footerSlice = createSlice({
  name: "footer",
  initialState,
  reducers: {
    getFooterDataPending: state => {
      state.isLoading = true;
    },
    getFooterDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.footerData = action.payload;
    },
    getFooterDataError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});
export const {
  getFooterDataPending,
  getFooterDataSuccess,
  getFooterDataError
} = footerSlice.actions;
export default footerSlice.reducer;