import moment from "moment";

export const normalize = (prop) => {
  return prop
    ?.toLowerCase()
    .replace(/\s+/g, "")
    .replace(/\//g, "")
    .replace(/-/g, "");
};

let generatedNumbers = new Set();

export const randomNumber = () => {
  let randomNumber;
  do {
    randomNumber = Math.random();
  } while (generatedNumbers.has(randomNumber));

  generatedNumbers.add(randomNumber);
  return randomNumber;
};

export const setCookies = (keyName, value) => {
  const cookie = [keyName, "=", JSON.stringify(value)].join("");
  document.cookie = cookie;
};

export const getCookie = (name) => {
  var result = document.cookie.match(new RegExp(name + "=([^;]+)"));
  result && (result = JSON.parse(result[1]));
  return result;
};

export const deleteCookie = (name) => {
  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const extractFirstWord = (inputString) => {
  const words = inputString.split(" ");
  return words[0];
};

export const capitalize = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const camelCase = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export const parseRouteString = (route, unCapitalize) => {
  const words = route.split("/").filter((word) => word !== "");
  let link = "";
  return words.map((word) => {
    const capitalizedWord =
      word.charAt(0).toUpperCase() + word.slice(1).replace(/([A-Z])/g, " $1");
    const unCapitalizedWord =
      word.charAt(0) +
      word
        .slice(1)
        .replace(/([A-Z])/g, "$1")
        .trim();
    link += `/${word}`;
    return {
      text: unCapitalize ? unCapitalizedWord : capitalizedWord,
      link: link,
    };
  });
};

export const setLocalStorageData = (key, value) => {
  if (typeof window !== "undefined") {
    if (typeof value === "string") {
      window.localStorage.setItem(key, value);
    } else {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  }
};

export const getLocalStorageData = (key) => {
  if (typeof window !== "undefined") {
    if (typeof key !== "undefined") {
      const data = window.localStorage.getItem(key);
      if (typeof data === "string") {
        return data;
      } else return JSON.parse(data);
    }
  }
};

export const getDateFormat = (date, type) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  if (type) {
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  } else {
    return `${year}-${month}-${day}`;
  }
};

export const fetchOrSaveLocalizeValue = (
  countryData,
  localeCode,
  isFetch = false
) => {
  const filteredRegionData = countryData?.filter((obj) =>
    obj.countryDetails.some(
      (item) =>
        item["countryCode"]?.replace(/\s/g, "")?.toLowerCase() ===
        localeCode?.[1]?.toLowerCase()
    )
  )[0];
  const filteredCountryData = filteredRegionData?.countryDetails?.filter(
    (obj) =>
      obj.countryCode?.replace(/\s/g, "")?.toLowerCase() ===
      localeCode?.[1]?.toLowerCase()
  )[0];
  const filteredLanguageData = filteredCountryData?.languageDetails?.filter(
    (obj) =>
      obj.languageCode?.replace(/\s/g, "")?.toLowerCase() === localeCode?.[0]
  )[0];
  let cookieData;
  if (
    (typeof countryData === "undefined" || countryData?.length === 0) &&
    localeCode?.[0]?.toLowerCase() === "en" &&
    localeCode?.[1]?.toLowerCase() === "th"
  ) {
    cookieData = {
      countryCode: "TH",
      countryName: "Thailand",
      languageCode: "EN",
      languageName: "English",
      regionCode: "APAC",
      countryNameEN: "Thailand",
    };
  } else {
    cookieData = {
      ...filteredLanguageData,
      countryCode: filteredCountryData?.countryCode?.replace(/\s/g, ""),
      regionCode: filteredRegionData?.regionCode,
      countryNameEN: filteredCountryData?.languageDetails?.[0]?.countryName,
    };
  }
  setCookies("config", {
    countryCode: cookieData?.countryCode,
    languageCode: cookieData?.languageCode,
    regionCode: cookieData?.regionCode,
    countryNameEN: cookieData?.countryNameEN,
  });
  if (isFetch) {
    return cookieData;
  }
};

export const removeLangFromRoute = (route) => {
  const parts = route.split("/");
  if (parts.length > 2) {
    parts.splice(1, 1);
  } else {
    return parts[0] + "/";
  }
  return parts.join("/");
};
//This function ensures to remove duplicates based on the data passed and the unique field passed.
export const removeDuplicateJsonObjects = (data, field) => {
  if (data) {
    const updatedData = data.reduce((acc, curr) => {
      if (!acc.find((item) => item[field] === curr[field])) {
        acc.push(curr);
      }
      return acc;
    }, []);
    return updatedData;
  }
};

export const updateRecentSearchState = (
  setArray,
  key,
  fieldName,
  updatedData,
  duplicateCheck,
  maxLength
) => {
  setArray((prevItems) => {
    const item = JSON.parse(getLocalStorageData(key));
    let newItem;
    let isRecentSearchDataExists;
    const dataToUpdate = fieldName ? updatedData[fieldName] : updatedData;
    const isDataExists = item.find(
      (x) => x?.airportCode === dataToUpdate?.airportCode
    );
    console.log(
      dataToUpdate,
      item,
      isDataExists,
      !duplicateCheck,
      "dataToUpdate"
    );

    if (!duplicateCheck) {
      isRecentSearchDataExists = item.find(
        (x) =>
          x?.flightNum === dataToUpdate?.flightNum &&
          x?.departureDate === dataToUpdate?.departureDate
      );
    }
    if (
      (duplicateCheck && !isDataExists) ||
      (!duplicateCheck && !isRecentSearchDataExists)
    ) {
      if (prevItems.length >= maxLength) {
        newItem = [...item.slice(1), dataToUpdate];
      } else {
        newItem = [...item, dataToUpdate];
      }
    } else {
      newItem = [...item];
    }

    localStorage.setItem(key, JSON.stringify(newItem));
    return newItem;
  });
};

export const extractSubArray = (array, step) => {
  const result = [];
  const length = array.length;

  if (step === 1) {
    return array;
  } else if (step === 2) {
    for (let i = 0; i < length; i++) {
      const subarray = [];
      subarray.push(array[i]);
      subarray.push(array[(i + 1) % length]);
      result.push(subarray);
    }
  } else {
    for (let i = 0; i < length; i += step - 1) {
      const subarray = [];
      if (step > 2) {
        for (let j = 0; j < step; j++) {
          if (j === step - 1) {
            const obj = array[(i + j) % length];
            subarray.push({ image: obj.image, altText: obj.altText });
          } else {
            subarray.push(array[(i + j) % length]);
          }
        }
      } else {
        for (let j = 0; j < step; j++) {
          subarray.push(array[(i + j) % length]);
        }
      }
      result.push(subarray);
    }
  }

  return result;
};

export const reverseJsonArray = (arr) => {
  const reverseArray = [];
  for (let i = arr.length - 1; i >= 0; i--) {
    reverseArray.push(arr[i]);
  }
  return reverseArray;
};

export const validateDepartDate = (date) => {
  const now = moment().startOf("day");
  const momentDepartDate = moment(date).startOf("day");
  return momentDepartDate.isBefore(now);
};

export const handleAmadeusRedirection = (url) => {
  window.open(url, "_blank");
};

export const isUserLoggedIn = () => {
  const accessToken = localStorage.getItem("accesstoken");
  const memberId = sessionStorage.getItem("memberId");

  if (accessToken && memberId) {
    return true;
  } else {
    return false;
  }
};

export const getTierImg = (name) => {
  switch (name) {
    case "member":
      return "../assets/member_tier.png";
    case "silver":
      return "../assets/silver_tier.png";
    case "gold":
      return "../assets/gold_tier.png";
    case "platinum":
      return "../assets/platinum_tier.png";
    default:
      return null;
  }
};

export const getCardImg = (name) => {
  switch (name) {
    case "member":
      return "../assets/silverCard.png";
    case "silver":
      return "../assets/goldCard.png";
    case "gold":
      return "../assets/platinumCard.png";
    default:
      return null;
  }
};
export const isValidEmail = (email) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return regex?.test(email);
};

export const blockSpecialChar = (str) => {
  const regex = /^[A-Za-z0-9]{0,100}$/;
  const isValid = regex.test(str);
  if (isValid) {
    return true;
  } else return false;
};

export const isValidRopId = (str) => {
  return (
    /^[a-zA-Z]{0,2}[0-9]{0,5}$/.test(str) &&
    /^[a-zA-Z]/.test(str[0]) &&
    /^[a-zA-Z]/.test(str[1])
  );
};

export const isvalidAlphabet = (str) => {
  return /^[a-zA-Z\s]{0,100}$/.test(str);
};

export const isvalidContact = (str) => {
  return /^[0-9-]{0,18}$/.test(str);
};
export const isvalidAddress = (str) => {
  return /^[a-zA-Z0-9\s/-]{0,100}$/.test(str);
};
export const isvalidLanguage = (str) => {
  return /^[A-Za-z0-9\s\W]*$/.test(str);
};

export const isValidPostal = (str) => {
  const regex = /^[A-Za-z0-9 ]{0,10}$/;
  const isValid = regex.test(str);
  if (isValid) {
    return true;
  } else return false;
};

export const formatDate = (date, type) => {
  if (type) {
    return date.format("ddd, DD MMM YYYY");
  } else {
    return date.format("DDMMYY");
  }
};

export const displayDateFormat = (date) => {
  return date.format("D MMMM YYYY");
};

export const normalDateFormat = (date) => {
  return date.format("DD MMM YYYY");
};
export const formatContact = (str) => {
  return str?.replace(/(\d{2})(\d{3})(\d{4})?(\d{5})/, "$1-$2-$3$4");
};

export const isValidBookingRef = (str) => {
  const string = /^[A-Za-z0-9\s]+$/.test(str);
  const number = /^[1-9\s]*$/.test(str);
  return string || number;
};
export const isNumberOnly = (str) => {
  const number = /^[0-9\s]*$/.test(str);
  return number;
};
export const checkValidity = (validationKey, enteredText) => {
  switch (validationKey) {
    case "rop":
      return isValidRopId(enteredText);
    case "name":
      return isvalidAlphabet(enteredText);
    case "email":
      return isValidEmail(enteredText);
    case "contact":
      return isvalidContact(enteredText);
    case "noSpecialChar":
      return blockSpecialChar(enteredText);
    case "postal":
      return isValidPostal(enteredText);
    case "bookingRef":
      return isValidBookingRef(enteredText);
    case "address":
      return isvalidAddress(enteredText);
    case "language":
      return isvalidLanguage(enteredText);
    case "number":
      return isNumberOnly(enteredText);
    default:
      return true;
  }
};

export const updateRecentSearchFlightStatus = (
  setArray,
  key,
  fieldName,
  updatedData,
  maxLength
) => {
  setArray((prevItems) => {
    const item = JSON.parse(sessionStorage.getItem(key));
    const length = item?.length ?? 0;
    let newItem;
    let isRecentSearchDataExists;
    // let isFollowedFlightExists;
    let isFirstElement = true;
    let dataToUpdate = fieldName ? updatedData[fieldName] : updatedData;
    console.log(item, isRecentSearchDataExists, dataToUpdate, "dataToUpdate");

    dataToUpdate.id = length + 1;
    if (item?.length > 0) {
      isFirstElement = false;
      if (dataToUpdate.type === "Flight Number") {
        isRecentSearchDataExists = item.find((x) => {
          console.log(
            x,
            x.departureDate,
            dataToUpdate.departureDate,
            moment(x.departureDate, "YYYY-MM-DD").utc().format("DDMMYY"),
            moment(dataToUpdate.departureDate, "YYYY-MM-DD")
              .utc()
              .format("DDMMYY"),
            "date"
          );

          return (
            x.departureDate === dataToUpdate.departureDate &&
            x.flightNum === dataToUpdate.flightNum &&
            x.type === "Flight Number"
          );
        });
        console.log(isRecentSearchDataExists, "test2");
      } else {
        isRecentSearchDataExists = item.find(
          (x) =>
            x.type === "Route" &&
            moment(x.departureDate).format("DDMMYY") ===
              moment(dataToUpdate.departureDate).format("DDMMYY") &&
            x.flightNum === dataToUpdate.flightNum
        );
      }
    } else {
      isRecentSearchDataExists = false;
      isFirstElement = true;
    }
    if (isFirstElement) {
      newItem = [dataToUpdate];
    } else if (!isRecentSearchDataExists) {
      if (prevItems?.length >= maxLength) {
        newItem = [...item.slice(1), dataToUpdate];
      } else {
        newItem = [...item, dataToUpdate];
      }
    } else {
      newItem = [...item];
    }
    console.log(newItem, isRecentSearchDataExists, "newItem");
    isRecentSearchDataExists = false;
    sessionStorage.setItem(key, JSON.stringify(newItem));
    return newItem;
  });
};

export const isEmptyChecker = (value) => {
  return (
    value === null ||
    value === undefined ||
    (typeof value === "string" && value.trim() === "") ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === "object" &&
      !Array.isArray(value) &&
      Object.keys(value).length === 0)
  );
};

export const DateFormatter = (date) => {
  const dateParts = date.split("-");
  return new Date(
    parseInt(dateParts[0]),
    parseInt(dateParts[1]) - 1,
    parseInt(dateParts[2])
  );
};

export const DateToDayConverter = (date) => {
  return moment(date).format("ddd DD, MMM YYYY");
};
export const calculateTimeLeft = (endTime) => {
  const remainingTime = endTime - Date.now();
  return {
    total: remainingTime,
    minutes: Math.floor(remainingTime / 1000 / 60),
    seconds: Math.floor((remainingTime / 1000) % 60),
  };
};

export const formatPriceInternational = (value) => {
  const formattedNumber = new Intl.NumberFormat("en-US").format(value);
  return formattedNumber;
};

export const handleClearDetails = () => {
  sessionStorage.clear();
  localStorage.removeItem("isROPLoggedIn");
  localStorage.removeItem("isCRMLoggedIn");
  localStorage.removeItem("accesstoken");
};

export const processJsonWithHtmlContent = (json, fieldNames) => {
  return json.map((item) => {
    const tempContainer = document.createElement("div");
    tempContainer.innerHTML = item.label;
    const fields = tempContainer.children;
    const rowData = {};
    Array.from(fields).forEach((field, index) => {
      const key = fieldNames[index].label;
      const value = field.textContent.trim();
      if (key) {
        rowData[key] = value;
      }
    });
    return rowData;
  });
};
