import { createSlice } from "@reduxjs/toolkit";
// import { FlightListsDataState } from "../models/models";

const initialState = {
  backTrackingAirportInfo: {},
  isLoading: false,
  hasData: false,
  error: ""
};
const backTrackingAirportSlice = createSlice({
  name: "backTrackingAirport",
  initialState,
  reducers: {
    backTrackingAirportRequest: state => {
      state.isLoading = true;
    },
    backTrackingAirportSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.backTrackingAirportInfo = action.payload;
    },
    backTrackingAirportFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    backTrackingAirportReset: state => {
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.backTrackingAirportInfo = {};
    }
  }
});
export const {
  backTrackingAirportRequest,
  backTrackingAirportSuccess,
  backTrackingAirportFailure,
  backTrackingAirportReset
} = backTrackingAirportSlice.actions;
export default backTrackingAirportSlice.reducer;