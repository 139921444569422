import { call, put, takeEvery } from "redux-saga/effects";
import { resendOTPFail, resendOTPPending, resendOTPSuccess } from "../slice/resendSlice";
import api from "../../../config/api";
const API_URL = "/profile/otp/resend";
const resendOTPData = async payload => {
  let headerData = {
    headers: {
      accessToken: "".concat(sessionStorage.getItem("temptoken")),
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "Authorization"
    }
  };
  let payloadData = {
    otpRef: payload.otpRef
  };
  const response = await api.post(API_URL, payloadData, headerData);
  sessionStorage.setItem("temptoken", response.headers.accesstoken);
  return response;
};
function* resendOTPSaga(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(resendOTPData, payload);
    yield put(resendOTPSuccess(response.data));
  } catch (error) {
    yield put(resendOTPFail(error));
  }
}
export function* watchResendOTP() {
  yield takeEvery(resendOTPPending.type, resendOTPSaga);
}