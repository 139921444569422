import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { getlanguageDataFailure, getlanguageDataSuccess, languageDataRequest } from "../../slice/languageSlice";
const API_URL = "./json/en-th/inspirationDealsCarousel.json";
function* getLanguagesData() {
  try {
    const response = yield call(axios.get, API_URL, {
      baseURL: "/"
    });
    yield put(getlanguageDataSuccess(response.data));
  } catch (error) {
    yield put(getlanguageDataFailure(error.message));
  }
}
export function* watchLanguageData() {
  yield takeLatest(languageDataRequest.type, getLanguagesData);
}