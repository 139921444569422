import { createSlice } from "@reduxjs/toolkit";
// import { FlightListsDataState } from "../models/models";

const initialState = {
  airAwardReviewPnrRMInfo: {},
  isLoading: false,
  hasData: false,
  error: ""
};
const airAwardReviewPnrRMSlice = createSlice({
  name: "airAwardReviewPnrRM",
  initialState,
  reducers: {
    airAwardReviewPnrRMRequest: state => {
      state.isLoading = true;
    },
    airAwardReviewPnrRMSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.airAwardReviewPnrRMInfo = action.payload;
    },
    airAwardReviewPnrRMFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    airAwardReviewPnrRMReset: state => {
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.airAwardReviewPnrRMInfo = {};
    }
  }
});
export const {
  airAwardReviewPnrRMRequest,
  airAwardReviewPnrRMSuccess,
  airAwardReviewPnrRMFailure,
  airAwardReviewPnrRMReset
} = airAwardReviewPnrRMSlice.actions;
export default airAwardReviewPnrRMSlice.reducer;