import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  loginDetails: {},
  isLoading: false,
  error: "",
  hasData: false,
  codeStatus: "pending"
};
const loginSlice = createSlice({
  name: "loginSection",
  initialState,
  reducers: {
    getLoginSectionDataPending: (state, action) => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    getLoginSectionDataSuccess: (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.loginDetails = action.payload;
      state.codeStatus = "success";
      sessionStorage.setItem("refNumber", action.payload.otpRefKey);
      sessionStorage.setItem("mailId", action.payload.email);
    },
    getLoginSectionDataFailure: (state, action) => {
      state.isLoading = false;
      state.codeStatus = "failure";
      state.error = action.payload;
    },
    resetLoginData: state => {
      state.codeStatus = "pending";
      state.error = "";
      state.loginDetails = {};
      state.hasData = false;
    }
  }
});
export const {
  getLoginSectionDataPending,
  getLoginSectionDataSuccess,
  getLoginSectionDataFailure,
  resetLoginData
} = loginSlice.actions;
export default loginSlice.reducer;