import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { flightStatusByRouteFailure, flightStatusByRouteSuccess, flightStatusByRouteRequest } from "../slice/flightRouteStatusSlice";
const FLIGHT_STATUS_ROUTE_URL = "flights/flight-status/by-route";
const accessToken = localStorage.getItem("accesstoken");
const FlightStatusRouteData = async payload => {
  var _payload$from, _payload$to;
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken
    },
    loader: true
  };
  let flightStatusPayload = {
    departureDate: payload === null || payload === void 0 ? void 0 : payload.date,
    departure: payload === null || payload === void 0 || (_payload$from = payload.from) === null || _payload$from === void 0 ? void 0 : _payload$from.airportCode,
    arrival: payload === null || payload === void 0 || (_payload$to = payload.to) === null || _payload$to === void 0 ? void 0 : _payload$to.airportCode
  };
  const response = await api.post(FLIGHT_STATUS_ROUTE_URL, flightStatusPayload, data);
  return response;
};
function* getFlightStatusByRouteData(action) {
  try {
    const {
      payload
    } = action;
    const response = yield call(FlightStatusRouteData, payload);
    yield put(flightStatusByRouteSuccess(response.data));
  } catch (error) {
    yield put(flightStatusByRouteFailure(error.message));
  }
}
export function* watchFlightStatusByRoute() {
  yield takeLatest(flightStatusByRouteRequest.type, getFlightStatusByRouteData);
}