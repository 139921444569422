import { call, put, takeLatest } from "redux-saga/effects";
import { removeSelectPassengerRequest, removeSelectPassengerSuccess, removeSelectPassengerFailure } from "../../slice/checkin/removeSelectPassengerSlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
import i18n from "../../../../i18n";
const fetchRemoveSelectPassengerData = async removeSelectPassengers => {
  const REMOVE_SELECTION_PASSENGER_API_URL = config.REMOVE_SELECT_PASSENGER_CHECKIN;
  const data = {
    mode: "no-cors",
    headers: {
      Authorization: localStorage.getItem("accesstoken"),
      "Accept-Language": "".concat(i18n.language),
      "Content-Type": "application/json"
    },
    loader: true,
    data: {
      journeyId: removeSelectPassengers === null || removeSelectPassengers === void 0 ? void 0 : removeSelectPassengers.journeyId,
      travelerIds: removeSelectPassengers === null || removeSelectPassengers === void 0 ? void 0 : removeSelectPassengers.travelerIds
    }
  };
  const response = await api.delete("".concat(REMOVE_SELECTION_PASSENGER_API_URL), data);
  return response;
};
function* getRemoveSelectPassengerData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchRemoveSelectPassengerData, payload);
    yield put(removeSelectPassengerSuccess(response.data));
  } catch (error) {
    yield put(removeSelectPassengerFailure(error));
  }
}
export function* watchRemoveSelectPassengerData() {
  yield takeLatest(removeSelectPassengerRequest.type, getRemoveSelectPassengerData);
}