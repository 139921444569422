import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { getLoginSectionDataPending, getLoginSectionDataSuccess, getLoginSectionDataFailure } from "../slice/loginSlice";
// import axios from "axios";

// import { tr } from "date-fns/locale";

const Login_API_URL = "profile/login";
const fetchLoginData = async loginPayload => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken"
    },
    loader: true
  };
  let loginPayloadData = {
    memberId: loginPayload.memberId,
    password: loginPayload.password
  };
  const response = await api.post(Login_API_URL, loginPayloadData, data);
  console.log("response", response);
  sessionStorage.setItem("temptoken", response.headers.accesstoken);
  return response;
};
function* getLoginData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchLoginData, payload);
    yield put(getLoginSectionDataSuccess(response.data));
  } catch (error) {
    yield put(getLoginSectionDataFailure(error));
  }
}
export function* watchLoginData() {
  yield takeLatest(getLoginSectionDataPending.type, getLoginData);
}