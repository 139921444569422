import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  helpDetails: {},
  isLoading: false,
  error: "",
  hasData: false
};
const helpSlice = createSlice({
  name: "help",
  initialState,
  reducers: {
    helpDataRequest: state => {
      state.isLoading = true;
    },
    helpDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.helpDetails = action.payload;
    },
    helpDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});
export const {
  helpDataRequest,
  helpDataFailure,
  helpDataSuccess
} = helpSlice.actions;
export default helpSlice.reducer;