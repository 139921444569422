import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  earnedMilesInfo: [],
  earnedMilesInfoData: {},
  savedWidgetData: null,
  isRedeem: "",
  noMilesArray: [],
  isLoading: false,
  hasData: false,
  error: ""
};
const earnedMilesSlice = createSlice({
  name: "earnedMiles",
  initialState,
  reducers: {
    earnedMilesRequest: state => {
      state.isLoading = true;
    },
    earnedMilesSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.earnedMilesInfo = action.payload.data;
    },
    earnedMilesFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    earned_miles_info_data: (state, action) => {
      state.earnedMilesInfoData = action.payload;
    },
    noMilesArrayState: (state, action) => {
      state.noMilesArray = action.payload;
    },
    savedWidgetDataRequest: (state, action) => {
      state.savedWidgetData = action.payload;
    },
    clearSavedWidgetDataRequest: (state, action) => {
      state.savedWidgetData = null;
    },
    clearEarnedMilesInfoData: (state, action) => {
      state.earnedMilesInfoData = {};
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
    },
    handleRedeemptionState: (state, action) => {
      state.isRedeem = action.payload;
    },
    clearHandleRedeemptionState: (state, action) => {
      state.isRedeem = "";
    }
  }
});
export const {
  earnedMilesRequest,
  earnedMilesSuccess,
  earnedMilesFailure,
  earned_miles_info_data,
  clearEarnedMilesInfoData,
  savedWidgetDataRequest,
  clearSavedWidgetDataRequest,
  handleRedeemptionState,
  clearHandleRedeemptionState,
  noMilesArrayState
} = earnedMilesSlice.actions;
export default earnedMilesSlice.reducer;