import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  resubscribeDetails: {},
  isLoading: false,
  error: {},
  hasData: false,
  resubscribestatus: {
    status: false,
    message: ""
  }
};
const reSubscribeResetSlice = createSlice({
  name: "resubscribeReset",
  initialState,
  reducers: {
    getResubscribeDataPending: (state, action) => {
      state.isLoading = true;
    },
    getReSubscribeSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.resubscribeDetails = action.payload;
    },
    getReSubscribeFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.response.data;
      if (state.error.code === "400") {
        state.resubscribestatus.message = "Invalid Email";
        state.resubscribestatus.status = false;
      }
    },
    resetReSubscribeData: state => {
      state.error = {};
      state.isLoading = false;
      state.hasData = false;
      state.resubscribeDetails = {};
    }
  }
});
export const {
  getResubscribeDataPending,
  getReSubscribeSuccess,
  getReSubscribeFailure,
  resetReSubscribeData
} = reSubscribeResetSlice.actions;
export default reSubscribeResetSlice.reducer;