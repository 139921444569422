import { call, put, takeEvery } from "redux-saga/effects";
import { getApplicationCardDataSuccess, getApplicationCardDataError, getApplicationCardDataRequest } from "../slice/applicationCardSlice";
import axios from "axios";
const API_URL = "/json/applicationCard.json";
function* fetchApplicationCardSaga() {
  try {
    const response = yield call(axios.get, API_URL, {
      baseURL: "/"
    });
    yield put(getApplicationCardDataSuccess(response.data));
  } catch (error) {
    yield put(getApplicationCardDataError(error.message));
  }
}
export function* watchFetchApplicationCardData() {
  yield takeEvery(getApplicationCardDataRequest.type, fetchApplicationCardSaga);
}