import { config } from "../../../config/global";
import api from "../../../config/api";
import { resendNomineeOTPFail, resendNomineeOTPRequest, resendNomineeOTPSuccess, sendNomineeOTPFail, sendNomineeOTPRequest, sendNomineeOTPSuccess, submitNomineeOTPFail, submitNomineeOTPRequest, submitNomineeOTPSuccess } from "../slice/addNomineeSlice";
import { call, put, takeLatest } from "redux-saga/effects";
const SEND_API_URL = config === null || config === void 0 ? void 0 : config.PAID_NOMINEE_SEND_OTP;
const RESEND_API_URL = config === null || config === void 0 ? void 0 : config.PAID_NOMINEE_RESEND_OTP;
const SUBMIT_API_URL = config === null || config === void 0 ? void 0 : config.PAID_NOMINEE_SUBMIT_OTP;
const sendNomineeOTPData = async nomineeDetails => {
  const {
    title,
    firstName,
    lastName,
    middleName,
    suffix,
    birthDate,
    royalOrchidPlusMemberId
  } = nomineeDetails;
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      Authorization: sessionStorage.getItem("accesstoken"),
      memberId: sessionStorage.getItem("memberId")
    },
    loader: true
  };
  const response = await api.post(SEND_API_URL, nomineeDetails, data);
  return response;
};
const sendNomineeResendOTPData = async nomineeDetails => {
  const {
    title,
    firstName,
    lastName,
    middleName,
    suffix,
    birthDate,
    royalOrchidPlusMemberId
  } = nomineeDetails;
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      Authorization: localStorage.getItem("accesstoken"),
      memberId: sessionStorage.getItem("memberId")
    },
    loader: true
  };
  const response = await api.post(RESEND_API_URL, nomineeDetails, data);
  return response;
};
const submitNomineeOTPData = async otpData => {
  const {
    otp,
    otpRefKey,
    nomineeDetails
  } = otpData;
  const {
    title,
    firstName,
    lastName,
    middleName,
    suffix,
    birthDate,
    royalOrchidPlusMemberId
  } = nomineeDetails;
  const data = {
    formOfPayment: "MILE",
    salutation: title,
    firstName: firstName,
    lastName: lastName,
    middleName: middleName,
    suffix: suffix,
    dateOfBirth: birthDate,
    ropNumber: royalOrchidPlusMemberId,
    otp: otp,
    otpRefKey: otpRefKey
  };
  const headerData = {
    headers: {
      "content-type": "application/json",
      Authorization: localStorage.getItem("accesstoken"),
      memberId: sessionStorage.getItem("memberId")
    }
  };
  const response = await api.post(SUBMIT_API_URL, data, headerData);
  return response;
};
function* sendOTPData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(sendNomineeOTPData, payload);
    yield put(sendNomineeOTPSuccess(response.data));
  } catch (error) {
    yield put(sendNomineeOTPFail(error));
  }
}
function* resendOTPData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(sendNomineeResendOTPData, payload);
    yield put(resendNomineeOTPSuccess(response.data));
  } catch (error) {
    yield put(resendNomineeOTPFail(error));
  }
}
function* submitOTPData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(submitNomineeOTPData, payload);
    yield put(submitNomineeOTPSuccess(response.data));
  } catch (error) {
    yield put(submitNomineeOTPFail(error.response.data));
  }
}
export function* watchSendNomineeOTPData() {
  yield takeLatest(sendNomineeOTPRequest.type, sendOTPData);
}
export function* watchResendNomineeOTPData() {
  yield takeLatest(resendNomineeOTPRequest.type, resendOTPData);
}
export function* watchSubmitNomineeOTPData() {
  yield takeLatest(submitNomineeOTPRequest.type, submitOTPData);
}