import { createSlice } from "@reduxjs/toolkit";
// import interlineJson from "../../../../../../../interlineNotCheckedin.json";

const initialState = {
  checkinDetails: {},
  isLoading: false,
  hasData: false,
  checkInSuccess: false,
  error: ""
};
const fetchCheckInSlice = createSlice({
  name: "checkin",
  initialState,
  reducers: {
    retrieveJourneyRequest: (state, action) => {
      state.isLoading = true;
      state.checkInSuccess = false;
      state.error = "";
    },
    retrieveJourneySuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.checkInSuccess = true;
      state.checkinDetails = action.payload;
    },
    retrieveJourneyFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.response.data.errors;
      console.log("error:", state.error);
      state.checkInSuccess = false;
      state.checkinDetails = {};
    },
    resetJourneyDetails: state => {
      state.isLoading = false;
      state.checkinDetails = {};
      state.checkInSuccess = false;
      state.hasData = false;
      state.error = "";
    }
  }
});
export const {
  retrieveJourneyRequest,
  retrieveJourneySuccess,
  retrieveJourneyFailure,
  resetJourneyDetails
} = fetchCheckInSlice.actions;
export default fetchCheckInSlice.reducer;