import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  changePasswordDetails: [],
  error: {},
  isLoading: false,
  hasData: false
};
const changePasswordSlice = createSlice({
  name: "changePassword",
  initialState,
  reducers: {
    changePasswordDataRequest: (state, action) => {
      state.isLoading = true;
      state.error = "";
    },
    changePasswordDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.changePasswordDetails = action.payload;
    },
    changePasswordDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    clearOtpRefKeyResponse: state => {
      state.changePasswordDetails = [];
    }
  }
});
export const {
  changePasswordDataRequest,
  changePasswordDataSuccess,
  changePasswordDataFailure,
  clearOtpRefKeyResponse
} = changePasswordSlice.actions;
export default changePasswordSlice.reducer;