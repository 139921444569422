import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  geolocationDetails: [],
  isLoading: false,
  error: "",
  hasData: false
};
const geoSlice = createSlice({
  name: "geo",
  initialState,
  reducers: {
    geolocationDataRequest: state => {
      state.isLoading = true;
    },
    getGeolocationDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.geolocationDetails = action.payload;
    },
    getGeolocationDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});
export const {
  geolocationDataRequest,
  getGeolocationDataFailure,
  getGeolocationDataSuccess
} = geoSlice.actions;
export default geoSlice.reducer;