import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  appStoreDetails: [],
  isLoading: false,
  error: "",
  hasData: false
};
const appStoreSlice = createSlice({
  name: "appStore",
  initialState,
  reducers: {
    appStoreDataRequest: state => {
      state.isLoading = true;
    },
    getAppStoreDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.appStoreDetails = action.payload;
    },
    getAppStoreDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});
export const {
  appStoreDataRequest,
  getAppStoreDataSuccess,
  getAppStoreDataFailure
} = appStoreSlice.actions;
export default appStoreSlice.reducer;