import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  subscribeDetails: {},
  isLoading: false,
  error: {},
  hasData: false,
  subscribestatus: {
    status: false,
    message: ""
  }
};
const unSubscribeResetSlice = createSlice({
  name: "subscribeReset",
  initialState,
  reducers: {
    getSubscribeDataPending: (state, action) => {
      state.isLoading = true;
      state.error = {};
      state.hasData = false;
      state.subscribeDetails = {};
      state.subscribestatus = {
        status: false,
        message: ""
      };
    },
    getSubscribeSuccess: (state, action) => {
      console.log("UnSubscribe playload", action.payload);
      state.isLoading = false;
      state.hasData = true;
      state.subscribeDetails = action.payload;
      state.subscribestatus.status = true;
    },
    getSubscribeFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.response.data;
      state.subscribestatus.status = false;
      if (state.error.code === "400") {
        state.subscribestatus.message = "Invalid Email";
        state.subscribestatus.status = false;
      }
    },
    resetSubscribeData: state => {
      state.error = {};
      state.isLoading = false;
      state.hasData = false;
      state.subscribeDetails = {};
      state.subscribestatus = {
        status: false,
        message: ""
      };
    }
  }
});
export const {
  getSubscribeDataPending,
  getSubscribeFailure,
  getSubscribeSuccess,
  resetSubscribeData
} = unSubscribeResetSlice.actions;
export default unSubscribeResetSlice.reducer;