import { all } from "redux-saga/effects";
import { watchRetrieveJourneyData } from "./checkin/checkinSaga";
import { watchAddPassengerData } from "./checkin/addPassengerSaga";
import { watchcancelCheckin } from "./cancelCheckinSaga/cancelCheckinSaga";
import { watchRetrieveCancelCheckin } from "./cancelCheckinSaga/cancelRetrieveJourneySaga";
import { watchRemoveSelectPassengerData } from "./selectpassenger/removeSelectPassengerSaga";
import { watchSelectPassengerData } from "./selectpassenger/selectPassengerSaga";
import { watchUpdatePassengerData } from "./selectpassenger/updatePassenerSaga";
import { watchFrequentFlyerProgramData } from "./passengerDetails/frequentFlyerProgramSaga";
import { watchNationalityCountryData } from "./passengerDetails/nationalityCountrySaga";
export default function* rootSaga() {
  yield all([watchRetrieveJourneyData(), watchAddPassengerData(), watchcancelCheckin(), watchRetrieveCancelCheckin(), watchRemoveSelectPassengerData(), watchSelectPassengerData(), watchUpdatePassengerData(), watchFrequentFlyerProgramData(), watchNationalityCountryData()]);
}