import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { sendChangePasswordOTPPending, sendChangePasswordOTPSuccess, sendChangePasswordOTPFailure } from "../slice/changePasswordOTPSlice";
import { config } from "../../../config/global";
const API_URL = config === null || config === void 0 ? void 0 : config.ROP_CHANGE_PASSWORD_SUBMIT_API;
const sendChangePasswordOTPData = async otpData => {
  const data = {
    otpRefKey: otpData.otpRefKey,
    otp: otpData.otp,
    newPin: sessionStorage.getItem("newPin")
  };
  const headerData = {
    headers: {
      Authorization: localStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
      source: "website"
    }
  };
  const response = await api.post(API_URL, data, headerData);
  return response;
};
function* sendOTPData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(sendChangePasswordOTPData, payload);
    yield put(sendChangePasswordOTPSuccess(response.data));
  } catch (error) {
    yield put(sendChangePasswordOTPFailure(error));
  }
}
export function* watchChangePasswordSendOTPData() {
  yield takeLatest(sendChangePasswordOTPPending.type, sendOTPData);
}