import { call, put, takeLatest } from "redux-saga/effects";
import { geolocationDataRequest, getGeolocationDataFailure, getGeolocationDataSuccess } from "../../slice/header/geolocationSlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
const data = {
  mode: "no-cors",
  headers: {
    "content-type": "application/json",
    "Access-Control-Expose-Headers": "cloudfront-viewer-city,cloudfront-viewer-country,cloudfront-viewer-country-name,cloudfront-viewer-country-region"
  },
  body: {}
};
const fetchGeolocation = async () => {
  const GEOLOCATION_API_URL = config.GEOLOCATION;
  const response = await api.get(GEOLOCATION_API_URL, data);
  return response;
};
function* getGeolocationData() {
  try {
    const response = yield call(fetchGeolocation);
    yield put(getGeolocationDataSuccess(response));
  } catch (error) {
    yield put(getGeolocationDataFailure(error.message));
  }
}
export function* watchGeolocationData() {
  yield takeLatest(geolocationDataRequest.type, getGeolocationData);
}