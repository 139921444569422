import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  applicationCardData: null,
  isLoading: false,
  error: "",
  hasData: false
};
const applicationCardSlice = createSlice({
  name: "applicationcard",
  initialState,
  reducers: {
    getApplicationCardDataRequest: state => {
      state.isLoading = true;
    },
    getApplicationCardDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.applicationCardData = action.payload;
    },
    getApplicationCardDataError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});
export const {
  getApplicationCardDataRequest,
  getApplicationCardDataSuccess,
  getApplicationCardDataError
} = applicationCardSlice.actions;
export default applicationCardSlice.reducer;