import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  headerDetails: [],
  data: null,
  countryData: null,
  globalSearchPreData: null,
  isLoading: false,
  error: false
};
const HeaderSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    headerDataRequest: state => {
      state.isLoading = true;
      state.error = null;
    },
    getHeaderDataSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    getHeaderDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    globalSearchPreRenderDataRequest: state => {
      state.isLoading = true;
      state.error = null;
    },
    globalSearchPreRenderDataSuccess: (state, action) => {
      state.globalSearchPreData = action.payload;
      state.isLoading = false;
    },
    globalSearchPreRenderDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});
export const {
  headerDataRequest,
  getHeaderDataSuccess,
  getHeaderDataFailure,
  globalSearchPreRenderDataRequest,
  globalSearchPreRenderDataSuccess,
  globalSearchPreRenderDataFailure
} = HeaderSlice.actions;
export default HeaderSlice.reducer;