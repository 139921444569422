import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  showErrorModal: false,
  errorDetails: {},
  errorTriggered: false,
  buttonAction: {}
};
const errorModalSlice = createSlice({
  name: "errorModal",
  initialState,
  reducers: {
    setError: (state, action) => {
      if (!state.errorTriggered) {
        state.showErrorModal = true;
        state.errorDetails = action.payload;
        state.errorTriggered = true;
      }
    },
    clearError: state => {
      state.showErrorModal = false;
      state.errorDetails = {};
      state.errorTriggered = false;
    },
    setButtonAction: (state, action) => {
      if (action.payload) {
        state.buttonAction = {
          buttonLabel: "primary",
          value: true
        };
      } else {
        state.buttonAction = {
          buttonLabel: "secondary",
          value: false
        };
      }
      state.showErrorModal = false;
    },
    resetButtonAction: state => {
      state.buttonAction = {};
    },
    resetModalTriggered: state => {
      state.errorTriggered = false;
      state.showErrorModal = false;
    }
  }
});
export const {
  setError,
  clearError,
  setButtonAction,
  resetButtonAction,
  resetModalTriggered
} = errorModalSlice.actions;
export default errorModalSlice.reducer;