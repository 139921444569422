import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  privilegeDetails: [],
  isLoading: false,
  error: "",
  hasData: false
};
const privilegeSlice = createSlice({
  name: "privilege",
  initialState,
  reducers: {
    getPrivilegeDataRequest: (state, action) => {
      state.isLoading = true;
    },
    getPrivilegeDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.privilegeDetails = action.payload;
    },
    getPrivilegeDataFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});
export const {
  getPrivilegeDataRequest,
  getPrivilegeDataSuccess,
  getPrivilegeDataFail
} = privilegeSlice.actions;
export default privilegeSlice.reducer;