import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  data: null,
  isLoading: false,
  error: false
};
const ModalSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    modalDataRequest: state => {
      state.isLoading = true;
      state.error = null;
    },
    getModalDataSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    getModalDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});
export const {
  modalDataRequest,
  getModalDataSuccess,
  getModalDataFailure
} = ModalSlice.actions;
export default ModalSlice.reducer;